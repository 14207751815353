import React, { useContext, useEffect, useState } from 'react';
import AsuntosNuevos from './AsuntosNuevos';
//import Modal from '../../comunes/Modal';
import ServicioContext from '../../../../context/servicio/servicioContext';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPlusCircle
    , faWindowClose
    , faCircle
    //, faInfoCircle
  } from '@fortawesome/free-solid-svg-icons';

const Asuntos = () => {
    //Context
    const servicioContext = useContext(ServicioContext);
    const { asuntosUsados, fnObtieneAsuntos } = servicioContext;

    //State
    const [nuevoAsunto, setNuevoAsunto] = useState(false);
    const [titulo, setTitulo] = useState('Historial de Asuntos');
    const [btnDisableNuevoAsunto, setBtnDisableNuevoAsunto] = useState(false);

    /*
    const modalTitle = 'Información Nuevo Asunto' 
    const modalBody = `Asunto es revisado por equipo El Prospector, y será utilizado en máximo 5 días hábiles
    *Si hay observaciones parar mejorar la solicitud, serás contactado y hay 24 hrs para aceptar los cambios. Si no hay respuesta saldrémos a prospectar con tu solicitud.
    *Opción de utilizar nombre de la empresa a prospectar es opcional.
    *60 carácteres disponibles (incluye variable nombre de empresa)
    *Una vez utilizado en la prospección, aparecerá la tasa de apertura correspondiente.
    *Si deseas volver a utilizar un asunto anterior, ponte en contacto con sac@elprospector.cl`
    */
    

    useEffect( () => {
        //console.log(asuntosUsados);
        if(asuntosUsados){
        
            if(asuntosUsados[0].f_tasaAper === 'ASU-002'){
                setBtnDisableNuevoAsunto(true);
            }else{
                setBtnDisableNuevoAsunto(false);
            }
            return;
        }

        fnObtieneAsuntos();
        // eslint-disable-next-line
    }, [asuntosUsados]);

    const OnClickNuevoAsuntos = () =>{
        if(btnDisableNuevoAsunto){
            Swal.fire({
                icon: 'error',
                title: 'Ya existe un asunto en revisión!',
                text: 'Si necesitas cargar otro, contacta a sac@elprospector.cl para cargar uno nuevo.',
            })
        }else{
            if(!nuevoAsunto){
                setTitulo('Ingresa tu nuevo Asunto');
            }else{
                setTitulo('Historial de Asuntos');
            }
            setNuevoAsunto(!nuevoAsunto);
        }
    }

    return (
        <div className='container'>
            {/*<div className='card border-primary mb-3'>
                <div className='card-header'><h4>Último utilizado</h4></div>
                <div className='card-body'>
                    <table className='table table-hover table-responsive'>
                        <thead>
                            <tr>
                            <th scope='col'>Titulo</th>
                            <th scope='col'>Fecha</th>
                            <th scope='col'>% Apertura</th>
                            </tr>
                        </thead>
                        {(ultAsunto) ? 
                            <tbody>
                                <tr className='table-light'>
                                <th scope='row'>{f_cis_email_subject}</th>
                                <td>{f_cis_fechaSQL}</td>
                                <td>{f_tasaAper}</td>
                                </tr>
                            </tbody>
                        : null
                        }
                    </table>
                </div>
            </div>*/}
            
            <div className='card border-info mb-3'>
                <div className='card-header resultados-header'>
                        <h4>{titulo}</h4>
                        <div className='flex-column'>
                            <span className='pr-2'>
                                <FontAwesomeIcon
                                    className='c-azulEP'
                                    icon={faCircle} size='xs'
                                /> Activo
                            </span>
                            <span className='pr-2'>
                                <FontAwesomeIcon
                                    className='c-celesteEP'
                                    icon={faCircle} size='xs'
                                /> Próximo a Usar
                            </span>
                            <span className='pr-2'>
                                <FontAwesomeIcon
                                    className='c-naranjoEP'
                                    icon={faCircle} size='xs'
                                /> En Revisión
                            </span>
                            <span className='pr-2'>
                                <FontAwesomeIcon
                                    className='c-grisEP'
                                    icon={faCircle} size='xs'
                                /> No Activo
                            </span>
                        </div>
                    {(nuevoAsunto) ? 
                            <button
                                className='btnCorto btn-cancelar'
                                data-toggle='tooltip' data-placement='top' title='Nuevo'
                                onClick={() => OnClickNuevoAsuntos()}
                            >
                                <FontAwesomeIcon
                                    className='btn-table-title-icon'
                                    icon={faWindowClose} size='lg'
                                />Cancelar
                            </button> :
                            <button
                                className='btnCorto btn-primario'
                                data-toggle='tooltip' data-placement='top' title='Nuevo'
                                onClick={() => OnClickNuevoAsuntos()}
                            >
                                <FontAwesomeIcon
                                    className='btn-table-title-icon'
                                    icon={faPlusCircle} size='lg'
                                />Nuevo
                            </button>
                    }
                </div>
                <div className='card-body'>
                {(nuevoAsunto) ? <AsuntosNuevos /> : 
                    <table className='table table-hover table-responsive'>
                        <thead>
                            <tr>
                                <th scope='col'>Fecha Creación</th>
                                <th scope='col'>Asunto</th>
                                <th scope='col'>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(asuntosUsados) ?
                            asuntosUsados.map((item, index) => {
                            return (
                                <tr className='table-light' key={index}>
                                    <td>{item.fecha_asu_creacion_txt}</td>
                                    <th scope='row'>{item.desc_asu_asunto}</th>
                                    <td className='text-center'>
                                    {
                                        (item.f_asu_estado === 1 ) ?
                                        <FontAwesomeIcon
                                            className='c-azulEP'
                                            icon={faCircle} size='lg'
                                        /> : (item.f_asu_estado === 2 ) ?
                                        <FontAwesomeIcon
                                            className='c-naranjoEP'
                                            icon={faCircle} size='lg'
                                        /> : (item.f_asu_estado === 3 ) ?
                                        <FontAwesomeIcon
                                            className='c-grisEP'
                                            icon={faCircle} size='lg'
                                        /> : (item.f_asu_estado === 5 ) ?
                                        <FontAwesomeIcon
                                            className='c-celesteEP'
                                            icon={faCircle} size='lg'
                                        /> : null
                                    }
                                    </td>
                                    
                                </tr>
                            );
                        })
                        : null}
                        </tbody>
                    </table>
                }
                </div>
            </div>
        </div>
    )
}

export default Asuntos;
