import React from 'react';
import Oportunidades from './Oportunidades';
//import Sinformacion from './Sinformacion';
//import Landing from './Landing';
//import DetalleApertura from './DetalleApertura';
import { 
  faSearchDollar
  //, faUserCheck
  //, faLaptopCode
  //faInfoCircle
} from '@fortawesome/free-solid-svg-icons';


/* Links y Rutas Sidebar (Barra Vertical) */
export const ResultadosNavTabs = [
  {
    title: 'Oportunidades',
    path: '#Oportunidades',
    icon: faSearchDollar,
    cNameLi: 'nav-item',
    cNameA: 'nav-link active',
    dataToggle: 'tab'
  }/*,
  {
    title: 'Solicitud Información',
    path: '#sinformacion',
    icon: faInfoCircle,
    cNameLi: 'nav-item',
    cNameA: 'nav-link',
    dataToggle: 'tab'
  },
  {
    title: 'Landing',
    path: '#landing',
    icon: faLaptopCode,
    cNameLi: 'nav-item',
    cNameA: 'nav-link',
    dataToggle: 'tab'
  },
  {
    title: 'Detalle Apertura',
    path: '#detalleApertura',
    icon: faUserCheck,
    cNameLi: 'nav-item',
    cNameA: 'nav-link',
    dataToggle: 'tab'
  }/*,
  {
    title: 'Pago',
    path: '#dropdown2',
    icon: faMoneyBillWave,
    cNameLi: 'nav-item',
    cNameA: 'nav-link',
    dataToggle: 'tab'
  }*/
];

export const ResultadosTabContent = [
    {
      id: 'Oportunidades',
      path: '#Oportunidades',
      component: <Oportunidades />,
      cName: 'tab-pane fade active show',
    }/*,
    {
      id: 'sinformacion',
      path: '#sinformacion',
      component: <Sinformacion />,
      cName: 'tab-pane fade',
    },
    {
      id: 'landing',
      path: '#lading',
      component: <Landing />,
      cName: 'tab-pane fade',
    },
    {
      id: 'detalleApertura',
      path: '#detalleApertura',
      component: <DetalleApertura />,
      cName: 'tab-pane fade',
    },
    {
      id: 'dropdown2',
      path: '#dropdown2',
      component: <p>Drop2. Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan DIY, art party locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh DIY salvia PBR, banh mi before they sold out farm-to-table VHS viral locavore cosby sweater.</p>,
      cName: 'tab-pane fade',
    },*/
  ];