import React, {useContext} from 'react';

import FichaContext from '../../../../../context/ficha/fichaContext';
import AlertaContext from '../../../../../context/alerta/alertaContext';

const Pantalla1 = ({formData, setForm, navigation, preguntasFicha}) => {
    const { resp0 } = formData

    const fichaContext = useContext(FichaContext);
    const { fnPantalla01Ficha } = fichaContext;

    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;

    const clickGuardarContinuar = (() => {
        //console.log(resp0);
        if (resp0 === ''){
            mostrarAlerta('¡Debe responder todas las preguntas!', 'alerta-error');
            return;
        }
        
        fnPantalla01Ficha(resp0.replace(/(\r\n|\n|\r)/g, '<br />'));
        navigation.next()
    });
    

    return (
        <div>
            { alerta? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null }
            <div className='form-group'>
                <h5>1.1- {preguntasFicha[0].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[0].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[0].desc_fip_preguntaT3}</h6>
                <textarea name='resp0' className='form-control' rows='1' defaultValue={resp0} onChange={setForm}></textarea>
            </div>

            {/*
            <div className='form-group'>
                <h5>{preguntasFicha[1].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[1].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[1].desc_fip_preguntaT3}</h6>
                <textarea name='lastName' className='form-control' rows='1' defaultValue={lastName} onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>{preguntasFicha[2].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[2].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[2].desc_fip_preguntaT3}</h6>
                <textarea name='nickName' className='form-control' rows='1' defaultValue={nickName} onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>{preguntasFicha[3].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[3].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[3].desc_fip_preguntaT3}</h6>
                <textarea className='form-control' rows='1' onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>{preguntasFicha[6].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[6].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[6].desc_fip_preguntaT3}</h6>
                <textarea className='form-control' rows='1' onChange={setForm}></textarea>
            </div>
            */}

            <button
                className='btn btn-primario col-2 mt-4'
                onClick={() => clickGuardarContinuar()}
            >Siguiente
            </button>
                {/*<Button
                    variant='contained'
                    color='primary'
                    style={{marginTop:'1rem'}}
                    onClick={() => navigation.next()}
                >Siguiente
                </Button>*/}
        </div>
    )
}

export default Pantalla1;
