import React from 'react';
import { faLock, faUserCircle, faFileContract } from '@fortawesome/free-solid-svg-icons';
import CambioContraseña from './CambioContraseña';
import Perfil from './Perfil';
import MultiStepForms from '../../layout/pages/ficha/MultiStepForms';

/* Links y Rutas Sidebar (Barra Vertical) */
export const ConfiguracionNavTabs = [
  {
    title: 'Contraseña',
    path: '#Contraseña',
    icon: faLock,
    cNameLi: 'nav-item',
    cNameA: 'nav-link active',
    dataToggle: 'tab'
  },
  {
    title: 'Perfil',
    path: '#profile',
    icon: faUserCircle,
    cNameLi: 'nav-item',
    cNameA: 'nav-link',
    dataToggle: 'tab'
  },
  {
    title: 'Ficha',
    path: '#ficha',
    icon: faFileContract,
    cNameLi: 'nav-item',
    cNameA: 'nav-link',
    dataToggle: 'tab'
  }/*,
  {
    title: 'Pago',
    path: '#dropdown2',
    icon: faMoneyBillWave,
    cNameLi: 'nav-item',
    cNameA: 'nav-link',
    dataToggle: 'tab'
  }*/
];

export const ConfiguracionTabContent = [
    {
      id: 'Contraseña',
      path: '#Contraseña',
      component: <CambioContraseña />,
      cName: 'tab-pane fade active show',
    },{
      id: 'profile',
      path: '#profile',
      component: <Perfil />,
      cName: 'tab-pane fade',
    },
    {
      id: 'ficha',
      path: '#ficha',
      component: <MultiStepForms />,
      cName: 'tab-pane fade',
    }/*,
    {
      id: 'dropdown2',
      path: '#dropdown2',
      component: <p>Drop2. Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan DIY, art party locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh DIY salvia PBR, banh mi before they sold out farm-to-table VHS viral locavore cosby sweater.</p>,
      cName: 'tab-pane fade',
    },*/
  ];