import 
{
    SPINNER_ON,
    SPINNER_OFF,
    SERVICIO_ERROR,
    CERRAR_SESION,
    ASUNTO_ULTIMO,
    MENSAJE_ULTIMO,
    ASUNTOS_USADOS,
    REPORTE_OBTENER,
    REPORTETOTAL_OBTENER,
    FECHASERVICIO_OBTENER,
    PERFILSERVICIO_OBTENER,
    DETALLEAPERTURA_OBTENER,
    MENSAJES_USADOS,
    MENSAJECREAR_EXITOSO,
    ASUNTOCREAR_EXITOSO,
    FECHAS_ACTUALIZACION,
    VALORACION_ENVIAR,
    RMCLIENTES_OBTENER,
    RMDATOS6M_OBTENER,
    RM6M_OBTENER,
    RMDETALLEEMPRESA6M,
    RMDETALLECONTACTO6M
}
from '../../types';

export default (state, action) => {
    switch(action.type){
        case SPINNER_ON:
            return{
                ...state,
                loadingSpinner: true
            }
        case SPINNER_OFF:
            return{
                ...state,
                loadingSpinner: false
            }
        case ASUNTO_ULTIMO:
            return{
                ...state,
                ultAsunto: action.payload
            }
        case MENSAJE_ULTIMO:
            return{
                ...state,
                ultMensaje: action.payload
            }
        case ASUNTOS_USADOS:
            return{
                ...state,
                asuntosUsados: action.payload
            }
        case REPORTE_OBTENER:
            return{
                ...state,
                reporteMensual: action.payload
            }
        case REPORTETOTAL_OBTENER:
            return{
                ...state,
                reporteMensualTotal: action.payload
            }
        case FECHASERVICIO_OBTENER:
            return{
                ...state,
                FechaServicio: action.payload
            }
        case FECHAS_ACTUALIZACION:
            return{
                ...state,
                fechasActualizacion: action.payload
            }
        case PERFILSERVICIO_OBTENER:
            return{
                ...state,
                perfilServicio: action.payload
            }
        case DETALLEAPERTURA_OBTENER:
            return{
                ...state,
                detalleApertura: action.payload
            }
        case MENSAJES_USADOS:
            return{
                ...state,
                mensajesUsados: action.payload
            }
        case MENSAJECREAR_EXITOSO:
        case ASUNTOCREAR_EXITOSO:
        case VALORACION_ENVIAR:
            return{
                ...state,
                //mensaje: action.payload
            }
        case RMCLIENTES_OBTENER:
            return{
                ...state,
                rmClientes: action.payload
            }
        case RMDATOS6M_OBTENER:
            return{
                ...state,
                rmDatos6M: action.payload
            }
        case RM6M_OBTENER:
        return{
            ...state,
            rm6M: action.payload
        }
        case RMDETALLEEMPRESA6M:
        return{
            ...state,
            rmDetalleEmpresa6M: action.payload
        }
        case RMDETALLECONTACTO6M:
        return{
            ...state,
            rmDetalleContacto6M: action.payload
        }
        case SERVICIO_ERROR:
        case CERRAR_SESION:
            return{
                ...state,
                ultAsunto: null,
                asuntosUsados: null,
                reporteMensual: null,
                perfilServicio: null,
                mensajesUsados: null,
                detalleApertura: null,
                mensaje: null,
                loadingSpinner: null
            }
        default:
            return state;
    }
}