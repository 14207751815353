//Alertas
export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';

//Autentication
export const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
export const REGISTRO_ERROR = 'REGISTRO_ERROR';
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CERRAR_SESION = 'CERRAR_SESION';
export const CAMBIOPASS_EXITOSO = 'CAMBIOPASS_EXITOSO';
export const CAMBIOPASS_ERROR = 'CAMBIOPASS_ERROR';
export const COMBO_PERIODO = 'COMBO_PERIODO';

//Ficha Cliente
export const PREGUNTAS_OBTENER = 'PREGUNTAS_OBTENER';
export const PREGUNTAS_GUARDAOK = 'PREGUNTAS_GUARDAOK';
export const RESPUESTAS_OBTENER = 'RESPUESTAS_OBTENER';
export const AREAHIJO_SELECCION = 'AREAHIJO_SELECCION';
export const FICHA_GUARDAROK = 'FICHA_GUARDAROK';
export const FICHA_ERROR = 'FICHA_ERROR';
//export const PANTALLA01_GUARDAR = 'PANTALLA01_GUARDAR';
//export const PANTALLA02_GUARDAR = 'PANTALLA02_GUARDAR';
//export const PANTALLA03_GUARDAR = 'PANTALLA03_GUARDAR';
//export const PANTALLA04_GUARDAR = 'PANTALLA04_GUARDAR';
//export const PANTALLA05_GUARDAR = 'PANTALLA05_GUARDAR';
export const R01STATE_GUARDAR = 'R01STATE_GUARDAR';
export const R02STATE_GUARDAR = 'R02STATE_GUARDAR';
export const R03STATE_GUARDAR = 'R03STATE_GUARDAR';
export const R04STATE_GUARDAR = 'R04STATE_GUARDAR';
export const R05STATE_GUARDAR = 'R05STATE_GUARDAR';
export const R06STATE_GUARDAR = 'R06STATE_GUARDAR';
export const R07STATE_GUARDAR = 'R07STATE_GUARDAR';
export const R08STATE_GUARDAR = 'R08STATE_GUARDAR';
export const R09STATE_GUARDAR = 'R09STATE_GUARDAR';
export const R10STATE_GUARDAR = 'R10STATE_GUARDAR';
export const R11STATE_GUARDAR = 'R11STATE_GUARDAR';
export const R12STATE_GUARDAR = 'R12STATE_GUARDAR';
export const R13STATE_GUARDAR = 'R13STATE_GUARDAR';
export const R14STATE_GUARDAR = 'R14STATE_GUARDAR';
export const R15STATE_GUARDAR = 'R15STATE_GUARDAR';
export const R16STATE_GUARDAR = 'R16STATE_GUARDAR';
export const R17STATE_GUARDAR = 'R17STATE_GUARDAR';
export const R18STATE_GUARDAR = 'R18STATE_GUARDAR';
export const R19STATE_GUARDAR = 'R19STATE_GUARDAR';
export const R20STATE_GUARDAR = 'R20STATE_GUARDAR';
export const R21STATE_GUARDAR = 'R21STATE_GUARDAR';
export const R22STATE_GUARDAR = 'R22STATE_GUARDAR';
export const R23STATE_GUARDAR = 'R23STATE_GUARDAR';
export const R24STATE_GUARDAR = 'R24STATE_GUARDAR';
export const R25STATE_GUARDAR = 'R25STATE_GUARDAR';
export const R26STATE_GUARDAR = 'R26STATE_GUARDAR';
export const R27STATE_GUARDAR = 'R27STATE_GUARDAR';
export const R28STATE_GUARDAR = 'R28STATE_GUARDAR';
export const R29STATE_GUARDAR = 'R29STATE_GUARDAR';
export const R30STATE_GUARDAR = 'R30STATE_GUARDAR';


//Servicio
export const SERVICIO_ERROR = 'SERVICIO_ERROR';
export const ASUNTO_ULTIMO = 'ULTIMO_ASUNTO';
export const MENSAJE_ULTIMO = 'MENSAJE_ULTIMO';
export const ASUNTOS_USADOS = 'ASUNTOS_USADOS';
export const REPORTE_OBTENER = 'REPORTE_OBTENER';
export const REPORTETOTAL_OBTENER = 'REPORTETOTAL_OBTENER';
export const FECHASERVICIO_OBTENER = 'FECHASERVICIO_OBTENER';
export const PERFILSERVICIO_OBTENER = 'PERFILSERVICIO_OBTENER';
export const MENSAJES_USADOS = 'MENSAJES_USADOS';
export const MENSAJECREAR_EXITOSO = 'MENSAJECREAR_EXITOSO';
export const ASUNTOCREAR_EXITOSO = 'ASUNTOCREAR_EXITOSO';
export const FECHAS_ACTUALIZACION = 'FECHAS_ACTUALIZACION';
export const RMCLIENTES_OBTENER = 'RMCLIENTES_OBTENER';
export const RMDATOS6M_OBTENER = 'RMDATOS6M_OBTENER';
export const RM6M_OBTENER = 'RM6M_OBTENER';
export const RMDETALLEEMPRESA6M = 'RMDETALLEEMPRESA6M';
export const RMDETALLECONTACTO6M = 'RMDETALLECONTACTO6M';

//Resultados
export const OPORTUNIDADES_OBTENER = 'OPORTUNIDADES_OBTENER';
export const SINFORMACION_OBTENER = 'SINFORMACION_OBTENER';
export const DETALLEAPERTURA_OBTENER = 'DETALLEAPERTURA_OBTENER';
export const RESULTADOS_ERROR = 'RESULTADOS_ERROR';
export const LANDING_OBTENER = 'LANDING_OBTENER';
export const VALORACION_ENVIAR = 'VALORACION_ENVIAR';
export const VALORACION_OBTENER = 'VALORACION_OBTENER';

//SPINNER
export const SPINNER_ON = 'SPINNER_ON';
export const SPINNER_OFF = 'SPINNER_OFF';

//EMAIL
export const EMAIL_ITEMAPROBADO = 'EMAIL_ITEMAPROBADO';
export const EMAIL_ERROR = 'EMAIL_ERROR';