import React, { useContext, useEffect } from 'react';
import Spinner from '../../layout/spinner/Spinner';
import ServicioContext from '../../../context/servicio/servicioContext';


const Perfil = () => {
    const servicioContext = useContext(ServicioContext);
    const { loadingSpinner, perfilServicio, fnPerfilServicio } = servicioContext;

    const { id_repm_estado, fecha_repm_ing, fecha_repm_fin, f_repm_dias_plan, f_repm_dias_contratados, fecha_repm_primer_envio } = perfilServicio || {}
    let descEstado = ''
    let badgeEstado = 'dark'
    if (id_repm_estado === 'E-CLI1'){
        descEstado = 'Activo'
        badgeEstado = 'success'
    }else if (id_repm_estado === 'E-CLI2'){
        descEstado = 'No Activo'
        badgeEstado = 'danger'
    }else if (id_repm_estado === 'E-CLI3'){
        descEstado = 'Setup'
        badgeEstado = 'info'
    }else if (id_repm_estado === 'E-CLI4'){
        descEstado = 'Suspendido'
        badgeEstado = 'warning'
    }

    useEffect(() => {
        if(perfilServicio) return;
        
        fnPerfilServicio();
        // eslint-disable-next-line
      }, [perfilServicio]);

    return (
        <div className='container'>
            <div className='card border-info mb-3'>
                <div className='card-header'><h4>Datos del Servicio</h4></div>
                <div className='card-body'>
                {(loadingSpinner === true) ? (<div className='spinner'><Spinner/></div>) : null}
                    <table className='table table-hover table-responsive'>
                        <thead>
                            <tr>
                            <th scope='col'>Estado</th>
                            <th scope='col'>Inicio Servicio</th>
                            <th scope='col'>Fin Servicio</th>
                            <th scope='col'>Plan Contratado</th>
                            <th scope='col'>Restantes</th>
                            <th scope='col'>Primer Envío</th>
                            </tr>
                        </thead>
                        {(perfilServicio) ? 
                            <tbody>
                                <tr className='table-light'>
                                {/*<th scope='row'>{f_cis_email_subject}</th>*/}
                                <td><span className={`badge badge-pill badge-${badgeEstado}`}>{descEstado}</span></td>
                                <td>{fecha_repm_ing}</td>
                                <td>{fecha_repm_fin}</td>
                                <td>{`${f_repm_dias_contratados} Días`}</td>
                                <td>{`${f_repm_dias_plan} Días`}</td>
                                <td>{fecha_repm_primer_envio}</td>
                                </tr>
                            </tbody>
                        : null
                        }
                        
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Perfil;
