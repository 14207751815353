import React, {useContext} from 'react';

import FichaContext from '../../../../../context/ficha/fichaContext';
import AlertaContext from '../../../../../context/alerta/alertaContext';

const Pantalla3 = ({formData, setForm, navigation, preguntasFicha}) => {

    const fichaContext = useContext(FichaContext);
    const { fnPantalla03Ficha } = fichaContext;

    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;

    const {resp7, resp8, resp9, resp10, resp11, resp12} = formData

    const clickGuardarContinuar = (() => {
        //console.log(selTamanio);
        if (resp7 === '' || resp8 === '' || resp9 === '' || resp10 === '' || resp11 === '' || resp12 === ''){
            mostrarAlerta('¡Debe responder todas las preguntas!', 'alerta-error');
            return;
        }
        
        fnPantalla03Ficha(
            resp7.replace(/(\r\n|\n|\r)/g, '<br />'),
            resp8.replace(/(\r\n|\n|\r)/g, '<br />'),
            resp9.replace(/(\r\n|\n|\r)/g, '<br />'),
            resp10.replace(/(\r\n|\n|\r)/g, '<br />'),
            resp11.replace(/(\r\n|\n|\r)/g, '<br />'),
            resp12.replace(/(\r\n|\n|\r)/g, '<br />')

        );
        navigation.next()
    });
    

    return (
        <div>
            { alerta? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null }
            <div className='form-group'>
                <h5>3.1- {preguntasFicha[6].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[6].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[6].desc_fip_preguntaT3}</h6>
                <textarea name='resp7' className='form-control' rows='3' defaultValue={resp7} onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>3.2- {preguntasFicha[7].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[7].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[7].desc_fip_preguntaT3}</h6>
                <textarea name='resp8' className='form-control' rows='3' defaultValue={resp8} onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>3.3- {preguntasFicha[8].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[8].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[8].desc_fip_preguntaT3}</h6>
                <textarea name='resp9' className='form-control' rows='3' defaultValue={resp9} onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>3.4- {preguntasFicha[9].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[9].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[9].desc_fip_preguntaT3}</h6>
                <textarea name='resp10' className='form-control' rows='3' defaultValue={resp10} onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>3.5- {preguntasFicha[10].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[10].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[10].desc_fip_preguntaT3}</h6>
                <textarea name='resp11' className='form-control' rows='3' defaultValue={resp11} onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>3.6- {preguntasFicha[11].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[11].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[11].desc_fip_preguntaT3}</h6>
                <textarea name='resp12' className='form-control' rows='3' defaultValue={resp12} onChange={setForm}></textarea>
            </div>

            <button
                className='btn btn-primario col-2 mt-4'
                onClick={() => clickGuardarContinuar()}
            >Siguiente
            </button>
            <button
                className='btn btn-secundario col-2 mt-4'
                onClick={() => navigation.previous()}
            >Volver
            </button>
                {/*<Button
                    variant='contained'
                    color='primary'
                    style={{marginTop:'1rem'}}
                    onClick={() => navigation.next()}
                >Siguiente
                </Button>*/}
        </div>
    )
}

export default Pantalla3;
