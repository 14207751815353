import React from 'react';
import Pantalla1 from '../StepForms/Pantalla1';
import Pantalla2 from '../StepForms/Pantalla2';
import Pantalla3 from '../StepForms/Pantalla3';
import Pantalla4 from '../StepForms/Pantalla4';
import Pantalla5 from '../StepForms/Pantalla5';
//import Names from '../StepForms/Names';
//import Address from '../StepForms/Address';
//import Contact from '../StepForms/Contact';
//import {Review} from '../StepForms/Review';
import Submit from '../StepForms/Submit';

import {Stepper, Step, StepLabel, StepContent} from '@material-ui/core';


export default function CustomizedSteppers({stepsPadre, stepsLabel, preguntasFicha, props}) {
  const steps = ['INFORMACIÓN GENERAL', 'DEFINICIÓN DE TARGET', 'PROPUESTA DE VALOR', 'INFORMACIÓN PARA ACTIVACIÓN', 'DERIVACIÓN DE OPORTUNIDADES', 'ENVIAR'];
  //console.log(stepsPadre);

  return (
    <div className=''>
        <Stepper
          orientation='vertical'
          alternativeLabel = {false}
          activeStep={stepsPadre.n}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
              >{label}</StepLabel>
              <StepContent>
                {
                    (() => {
                        switch(stepsPadre.n){
                            case 0:
                              return <Pantalla1 {...props} preguntasFicha={preguntasFicha}/>
                            case 1:
                              return <Pantalla2 {...props} preguntasFicha={preguntasFicha}/>
                            case 2:
                              return <Pantalla3 {...props} preguntasFicha={preguntasFicha}/>
                            case 3:
                              return <Pantalla4 {...props} preguntasFicha={preguntasFicha}/>
                            case 4:
                              return <Pantalla5 {...props} preguntasFicha={preguntasFicha}/>
                            //case 5:
                              //return <Submit {...props}/>
                              //return <Review {...props} props={props} steps={steps}/>
                            //case 6:
                            //  return <Submit {...props}/>
                            default:
                                // Nada
                        }
                    }).call(this)
                }
              </StepContent>
            </Step>
          ))}
          {(stepsPadre.id === 'ENVIAR') ? <Submit {...props} preguntasFicha={preguntasFicha}/> : null}
        </Stepper>
    </div>
  );
}