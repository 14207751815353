import React, {Fragment, useState, useContext, useEffect} from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faLock } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../layout/spinner/Spinner';


import AlertaContext from '../../context/alerta/alertaContext';
import AuthContext from '../../context/autentication/authContext';

const LoginSesion = (props) => {
    //Context Alerta
    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;

    //Context Auth
    const authContext = useContext(AuthContext);
    const { mensaje, autenticado, loading, iniciarSesion } = authContext;
    
    //State para Iniciar Sesion
    const [usuario, setUsuario] = useState({
        desc_usu_email:'',
        desc_usu_password: ''
    });

    useEffect(() => {
        if(autenticado) {
           props.history.push('/indicadores');
        }
        
        if(mensaje) {
            mostrarAlerta(mensaje.msg, mensaje.categoria);
        }
        //eslint-disable-next-line
    }, [mensaje, autenticado, props.history]);
    
    const {desc_usu_email, desc_usu_password} = usuario;

    const OnChange = e =>{
        setUsuario({
            ...usuario, 
            [e.target.name] : e.target.value
        })
    }

    const onSubmitLogin = e => {
        e.preventDefault();

        //Validar
        if (desc_usu_email.trim() === '' || desc_usu_password.trim() === ''){
            mostrarAlerta('¡Todos los campos son obligatorios!', 'alerta-error');
            //console.log('Campos Obligatorios');
            return;
        }

        //Enviamos datos para iniciar sesion
        //console.log(desc_usu_email, desc_usu_password);
        iniciarSesion({ desc_usu_email, desc_usu_password });      
    }

    return(
    <Fragment>
    <div className='fondo-azulep'>
        <div className='form-usuario'>
            { alerta ?  (<div className={`alerta ${alerta.categoria}`}> {alerta.msg} </div>) : null}
            <div className='contenedor-form'>
                <div className='row'>
                    <form className='formControl' onSubmit={onSubmitLogin}> 
                        <img className='img-login col-sm-12'
                            src={require('../../img/ElProspectorCL.png')}
                            alt='Logo El Prospector'
                        />
                        <TextField
                            id='desc_usu_email'
                            name='desc_usu_email'
                            className='col-sm-12 md-6'
                            label='Usuario'
                            variant='filled'
                            type='text'
                            onChange={OnChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <FontAwesomeIcon className='icoLogin' icon={faUserCircle} />
                                    </InputAdornment>
                                ),
                            }}
                        />  
                        <TextField
                            id='desc_usu_password'
                            name='desc_usu_password'
                            className='col-sm-12 md-6 mt-4'
                            label='Contraseña'
                            variant='filled'
                            type='password'
                            onChange={OnChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                    <FontAwesomeIcon className='icoLogin' icon={faLock} />
                                    </InputAdornment>
                                ),
                            }}
                        />  
                        {(loading === true) ? (<div className='spinner'><Spinner/></div>) : null}
                        <input 
                            type='submit'
                            className='btn btn-primario col-sm-12 mt-4'
                            value='Iniciar Sesión'
                        />
                    </form>
                </div>
            </div>
        </div>
    </div>
    </Fragment>
    )
}

export default LoginSesion;