import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SidebarData, NavbarData } from './SidebarData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../../../context/autentication/authContext';
import ServicioContext from '../../../context/servicio/servicioContext';


function Navbar() {
  const authContext = useContext(AuthContext);
  const { usuarioAutenticado, usuario, cerrarSesion } = authContext;

  const servicioContext = useContext(ServicioContext);
  const { fnServicioCerrarSesion } = servicioContext;
  
  const [sidebar, setSidebar] = useState(false);

  useEffect(()=> {
    usuarioAutenticado();
    // eslint-disable-next-line
  }, [])

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const cierraSesion = () => {
    cerrarSesion();
    fnServicioCerrarSesion();
  }


  return (
      <Fragment>
        <div className='navbar navbar-expand-lg navbar-dark fixed-top'>
          <Link to='#' className='menu-bars' data-toggle='tooltip' data-placement='top' title='Menú'>
            <FontAwesomeIcon className='nav-menu-bar' icon={faBars} size='lg' onClick={showSidebar}/>
          </Link>
          {(usuario) ?
            <p className='nombre-usuario nav-title'><FontAwesomeIcon icon={faUser} size='lg'/>
              <span> {usuario.desc_usu_nombre} - {usuario.desc_cli_cliente}</span>
              
            </p> : null}
          {NavbarData.map((item, index) => {
              return (
                  <Link key={index} to={item.path} data-toggle='tooltip' data-placement='top' title={item.title}>
                    {item.icon}
                  </Link>
              );
            })}
            <Link 
              to='#'
              className='menu-bars'
              data-toggle='tooltip'
              data-placement='top'
              title='Cerrar Sesión'
              onClick={ () => cierraSesion()}
            >
              <Fragment><FontAwesomeIcon className='nav-menu-icon' icon={faSignOutAlt} size='lg'/></Fragment>
            </Link>
        </div>

        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <FontAwesomeIcon icon={faTimes} size='lg'/>
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
            <li className='nav-text small'>
              <Link 
                to='#'
                onClick={ () => cierraSesion()}
              >
                <FontAwesomeIcon icon={faSignOutAlt} size='lg' />
                <span>Cerrar Sesión</span>
              </Link>
            </li>
          </ul>
        </nav>
        {/*<Home />*/}
    </Fragment>
  );
}

export default Navbar;
