import React, { useState, useContext, useRef } from 'react';
import Swal from 'sweetalert2'
import ServicioContext from '../../../../context/servicio/servicioContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTimesCircle, faCheckCircle
  } from '@fortawesome/free-solid-svg-icons';

const MensajesNuevo = () => {
    //Context
    const servicioContext = useContext(ServicioContext);
    const { fnMensajeCrear } = servicioContext;

    //State
    const [desc_msg_mensaje, setDesc_msg_mensaje ] = useState({
        desc_msg_mensaje:'',
    });
    const [desc_msg_link, setDesc_msg_link ] = useState({
        desc_msg_link:'',
    });
    const [id_msg_tipo_envio, setId_msg_tipo_envio ] = useState(0);
    const [caracterRest, setCaracterRest] = useState(2000);
    const [btnDisableMsg, setBtnDisableMsg] = useState(false);
    const [badgeCaracterRest, setBadgeCaracterRest] = useState('info');
    const [badgeValidaEmpresa, setBadgeValidaEmpresa] = useState({
        icono: faTimesCircle,
        claseIcono: 'KO',
        texto: 'Nombre de Empresa NO ingresado'
    });
    const [badgeValidaContacto, setBadgeValidaContacto] = useState({
        icono: faTimesCircle,
        claseIcono: 'KO',
        texto: 'Nombre de Contacto NO ingresado'
    });
    const [badgeValidaLink, setBadgeValidaLink] = useState({
        icono: faTimesCircle,
        claseIcono: 'KO',
        texto: 'Link web o landing NO ingresado'
    });
    
    //Variables
    const caracterMax = 2000;
    const ContactEmpresa = '~Contact.Company~';//'~Contact._Empresa~';
    const ContactFirstName = '~Contact.FirstName~';
    const ContactLink = '~Contact._Landing~';
    const IdTipoMensaje = [
        {idTipoMensaje: 21, descTipoMensaje: 'Envio 1 - Mensaje Inicial'},
        {idTipoMensaje: 22, descTipoMensaje: 'Envio 2 - Seguimiento 1'},
        {idTipoMensaje: 23, descTipoMensaje: 'Envio 3 - Seguimiento 2'},
    ]
    
    //Refs
    const desc_msg_mensajeRef = useRef('');

    //Funciones
    const OnChangeMsg = e =>{
        e.preventDefault();
        //const msg = e.target.value.replace(/(\r\n|\n|\r)/g, '<br />');
        setDesc_msg_mensaje({
            //[e.target.name] : msg
            [e.target.name] : e.target.value
        });
        largoCadena(e.target.value);
    }

    const largoCadena = (cadena) => {
        //console.log(e);
        
        setCaracterRest(caracterMax - cadena.length);
        if( (caracterMax - cadena.length) < 0){
            setBadgeCaracterRest('dark');
            setBtnDisableMsg(true);
        }else if( (caracterMax - cadena.length) < 100){
            setBadgeCaracterRest('danger');
            setBtnDisableMsg(false);
        }else if( (caracterMax - cadena.length) < 500){
            setBadgeCaracterRest('warning');
            setBtnDisableMsg(false);
        }else if( (caracterMax - cadena.length) <= caracterMax){
            setBadgeCaracterRest('info');
            setBtnDisableMsg(false);
        }

        const empresaValidado = (cadena.indexOf(ContactEmpresa) > -1);
        const contactoValidado = (cadena.indexOf(ContactFirstName) > -1);
        const linkValidado = (cadena.indexOf(ContactLink) > -1);
        //console.log(empresaValidado, contactoValidado, linkValidado);
        
        if(!empresaValidado){
            setBadgeValidaEmpresa({
                icono: faTimesCircle,
                claseIcono: 'KO',
                texto: 'Nombre de Empresa NO ingresado'
            });
        }else{
            setBadgeValidaEmpresa({
                icono: faCheckCircle,
                claseIcono: 'OK',
                texto: 'Nombre de Empresa ingresado'
            });
        }
        
        if(!contactoValidado){
            setBadgeValidaContacto({
                icono: faTimesCircle,
                claseIcono: 'KO',
                texto: 'Nombre de Contacto NO ingresado'
            });
        }else{
            setBadgeValidaContacto({
                    icono: faCheckCircle,
                    claseIcono: 'OK',
                    texto: 'Nombre de Contacto ingresado'
            });
        }

        if(!linkValidado){
            setBadgeValidaLink({
                    icono: faTimesCircle,
                    claseIcono: 'KO',
                    texto: 'Link landing NO ingresado'
            });
        }else{
            setBadgeValidaLink({
                    icono: faCheckCircle,
                    claseIcono: 'OK',
                    texto: 'Link landing ingresado'
            });
        }
        
        desc_msg_mensajeRef.current.focus();
    }

    const btnEmpresa = () => {
        //console.log('click Empresa')
        const nuevaCadena = `${desc_msg_mensaje.desc_msg_mensaje} ${ContactEmpresa} `;
        setDesc_msg_mensaje({desc_msg_mensaje: nuevaCadena});
        largoCadena(nuevaCadena);
    }

    const btnContacto = () => {
        //console.log('click Empresa')
        const nuevaCadena = `${desc_msg_mensaje.desc_msg_mensaje} ${ContactFirstName} `;
        setDesc_msg_mensaje({desc_msg_mensaje: nuevaCadena});
        largoCadena(nuevaCadena);
    }

    const btnLink = () => {
        //console.log('click Empresa')
        Swal.fire({
            title: 'Tu link Landing',
            inputPlaceholder: 'www.mylanding.cl',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            confirmButtonColor: '#034673',
            cancelButtonColor: '#555655',
            showCancelButton: true,
            confirmButtonText: 'Cargar',
            showLoaderOnConfirm: true,
            preConfirm: (linkMsg) => {
                if(linkMsg.trim() === '' || linkMsg.trim() === undefined){
                Swal.showValidationMessage(
                    `Texto ingresado no valido`
                    )      
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            //console.log(result);
            if(result.isConfirmed === true){
                const nuevaCadena = `${desc_msg_mensaje.desc_msg_mensaje} ${ContactLink} `;
                setDesc_msg_mensaje({desc_msg_mensaje: nuevaCadena});
                setDesc_msg_link({desc_msg_link: result.value.trim()});
                largoCadena(nuevaCadena);
            }
          }).catch(error => {
            Swal.showValidationMessage(
                `Request failed: ${error}`
              )
          })    
    }

    const selectTipoMensaje = (e) => {
        setId_msg_tipo_envio(e.target.value);

    }

    const onSubmitMsg = (msg, link, tipoEnvio) =>{
        //e.preventDefault();
        console.log(msg, link, tipoEnvio);

        //control de vacios
        if(msg.desc_msg_mensaje === '' || tipoEnvio === 0 || tipoEnvio === '0'){
            Swal.fire({
                title: 'Error - Falta Información',
                text: 'Complete tanto el Mensaje y también el Tipo de mensaje',
                icon: 'error'
            })
            return;
        }
        

        const mensajePrevio = msg.desc_msg_mensaje;
        const desc_msg_mensaje = ({
            desc_msg_mensaje: mensajePrevio.replace(/(\r\n|\n|\r)/g, '<br />')
        });
        //console.log(desc_msg_mensaje);
        //console.log(mensajePrevio);
        //console.log(desc_msg_mensaje);
        Swal.fire({
            title: 'Revisión Final',
            text: '¿Estas seguro de utilizar este nuevo mensaje de prospección?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#034673',
            cancelButtonColor: '#555655',
            confirmButtonText: 'Si, enviar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
                fnMensajeCrear(desc_msg_mensaje, link, tipoEnvio);
                Swal.fire(
                    '¡Solicitud nuevo mensaje enviada!',
                    'Será utilizado para la prospección desde la próxima semana',
                    'success'
                );
            }
          })
        //fnMensajeCrear(desc_msg_mensaje);
    }

    return (
        <div className='container'>
            <div className='card border-info mb-3'>
            <div className='form-group'>
                <label name='validadorCaracterMax' htmlFor='desc_asu_asunto' className='d-block ml-2'>
                    <span className={`badge badge-pill badge-${badgeCaracterRest}`}>{caracterRest}</span> Caracteres Disponibles
                </label>
                <label name='validadorContactEmpresa' className='d-block ml-2'>
                    <FontAwesomeIcon
                        className={`validadorEmpresa-${badgeValidaEmpresa.claseIcono}`}
                        icon={badgeValidaEmpresa.icono}
                        size='lg'
                    /> {badgeValidaEmpresa.texto}
                </label>
                <label name='validadorContactContacto' className='d-block ml-2'>
                    <FontAwesomeIcon
                        className={`validadorEmpresa-${badgeValidaContacto.claseIcono}`}
                        icon={badgeValidaContacto.icono}
                        size='lg'
                    /> {badgeValidaContacto.texto}
                </label>
                <label name='validadorContactLink' className='d-block ml-2'>
                    <FontAwesomeIcon
                        className={`validadorEmpresa-${badgeValidaLink.claseIcono}`}
                        icon={badgeValidaLink.icono}
                        size='lg'
                    /> {badgeValidaLink.texto}
                </label>
                <div>
                    {/*
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <button class='btn btn-naranjo btn-sm' type="button">Button</button>
                        </div>
                        <input type="text" class="form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1"/>
                    </div>
                    */}
                    <button type='button' className='btnCorto sm btn-naranjo ml-2' onClick={() => btnEmpresa()}>Insertar Empresa</button>
                    <button type='button' className='btnCorto sm btn-naranjo ml-2' onClick={() => btnContacto()}>Insertar Contacto</button>
                    <button type='button' className='btnCorto sm btn-naranjo ml-2' onClick={() => btnLink()}>Insertar Landing</button>
                    <select className='form-select form-select-lg btn-naranjo rounded ml-2' aria-label='.form-select-lg example'
                        onClick={(e) => selectTipoMensaje(e)}
                    >
                        <option value={0} selected>Tipo de Mensaje</option>
                        {IdTipoMensaje.map((item, index) => (
                                <option key={index} value={item.idTipoMensaje}>{item.descTipoMensaje}</option>
                            ))}
                    </select>
                </div>
                <textarea 
                    className='form-control mt-2'
                    id='desc_msg_mensaje'
                    name='desc_msg_mensaje'
                    rows='10'
                    placeholder='Hola, dejanos tu nuevo mensaje para actualizar tu servicio.'
                    onChange={OnChangeMsg}
                    maxLength={caracterMax}
                    value={desc_msg_mensaje.desc_msg_mensaje}
                    ref={desc_msg_mensajeRef}
                />
                <button
                    className='btn btn-primario col-sm-12 mt-4'
                    onClick={() => onSubmitMsg(desc_msg_mensaje, desc_msg_link, id_msg_tipo_envio)}
                    disabled={btnDisableMsg}
                >
                    Enviar Solicitud
                </button>
            </div>
            </div>
        </div>
    )
}

export default MensajesNuevo;