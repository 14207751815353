import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';
import FichaContext from './fichaContext';
import FichaReducer from './fichaReducer';

import 
{
    SPINNER_ON,
    SPINNER_OFF,
    PREGUNTAS_OBTENER,
    AREAHIJO_SELECCION,
    R01STATE_GUARDAR,
    R02STATE_GUARDAR,
    R03STATE_GUARDAR,
    R04STATE_GUARDAR,
    R05STATE_GUARDAR,
    R06STATE_GUARDAR,
    R07STATE_GUARDAR,
    R08STATE_GUARDAR,
    R09STATE_GUARDAR,
    R10STATE_GUARDAR,
    R11STATE_GUARDAR,
    R12STATE_GUARDAR,
    R13STATE_GUARDAR,
    R14STATE_GUARDAR,
    R15STATE_GUARDAR,
    R16STATE_GUARDAR,
    R17STATE_GUARDAR,
    R18STATE_GUARDAR,
    R19STATE_GUARDAR,
    R20STATE_GUARDAR,
    FICHA_GUARDAROK,
    RESPUESTAS_OBTENER,
    FICHA_ERROR
}
from '../../types';

const FichaState = props => {
    const initialState = {
        mensaje: null,
        loading: null,
        preguntasFicha: [],
        pantallasFicha: [{id: 'SIN INFORMACION', n: 0}],
        areaPadreFicha: [],
        areaHijoFicha: [],
        areaHijoSeleccion: [],
        sectorFicha: [],
        tamanioFicha: [],
        plataformaCorreoFicha: [],
        basePropiaFicha: [],
        r01Ficha: null,
        r02Ficha: null,
        r03Ficha: null,
        r04Ficha: null,
        r05Ficha: null,
        r06Ficha: null,
        r07Ficha: null,
        r08Ficha: null,
        r09Ficha: null,
        r10Ficha: null,
        r11Ficha: null,
        r12Ficha: null,
        r13Ficha: null,
        r14Ficha: null,
        r15Ficha: null,
        r16Ficha: null,
        r17Ficha: null,
        r18Ficha: null,
        r19Ficha: null,
        r20Ficha: null,
        respuestas: [],
        execEnviarFinalizado: []
    }

    const [state, dispatch] = useReducer(FichaReducer, initialState);

    //Funciones

    //Preguntas
    const fnPreguntasObtener = async ()  => {
        try {
            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await clienteAxios.post('/api/ficha/preguntasObtener');

            //401 = SIN DATOS pero ejecucion con exito en SQL
            if (respuesta.data.recordset[0].id_status === 401){

                const alerta = {
                    msg: respuesta.data.recordset[0].msg,
                    categoria: 'alerta-error'
                }

                dispatch({
                    type: FICHA_ERROR,
                    payload: alerta
                });
            
                dispatch({
                    type: SPINNER_OFF
                });    
                //console.log(alerta);

            }else {
            //Ejecucion OK y devuelve datos en SQL
                //console.log(respuesta.data.recordsets[0]);
                const payloadPreguntas = respuesta.data.recordsets;
                //console.log(payloadPreguntas);

                dispatch({
                    type: PREGUNTAS_OBTENER,
                    payload: payloadPreguntas
                });
                //return;

                dispatch({
                    type: SPINNER_OFF
                });    
            }

        } catch (error) {
            const alerta = {
                msg: error.response,
                categoria: 'alerta-error'
            }

            dispatch({
                type: FICHA_ERROR,
                payload: alerta
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    //Respuestas (en caso de ya haber respondido las fichas)
    const fnRespuestasObtener = async ()  => {
        try {
            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await clienteAxios.post('/api/ficha/respuestasObtener');

            //401 = SIN DATOS pero ejecucion con exito en SQL
            if (respuesta.data.recordset[0].id_status === 401){

                const alerta = {
                    msg: respuesta.data.recordset[0].msg,
                    categoria: 'alerta-error'
                }

                dispatch({
                    type: FICHA_ERROR,
                    payload: alerta
                });
            
                dispatch({
                    type: SPINNER_OFF
                });    
                //console.log(alerta);

            }else {
            //Ejecucion OK y devuelve datos en SQL
                //console.log(respuesta.data.recordsets[0]);
                const payloadRespuestas = respuesta.data.recordsets[0];
                //console.log(payloadPreguntas);

                dispatch({
                    type: RESPUESTAS_OBTENER,
                    payload: payloadRespuestas
                });
                //return;

                dispatch({
                    type: SPINNER_OFF
                });    
            }

        } catch (error) {
            const alerta = {
                msg: error.response,
                categoria: 'alerta-error'
            }

            dispatch({
                type: FICHA_ERROR,
                payload: alerta
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnSeleccionAreaHijo = async (selAreaHijo)  => {
        try {
            dispatch({
                type: SPINNER_ON
            });

            //const respuesta = await clienteAxios.post('/api/ficha/preguntasObtener');

            const payload = selAreaHijo;
            console.log(payload);

            dispatch({
                type: AREAHIJO_SELECCION,
                payload: payload
            });
            //return;

            dispatch({
                type: SPINNER_OFF
            });

        } catch (error) {
            const alerta = {
                msg: error.response,
                categoria: 'alerta-error'
            }

            dispatch({
                type: FICHA_ERROR,
                payload: alerta
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }


    const fnPantalla01Ficha = async (r01)  => {
        try {
            dispatch({
                type: SPINNER_ON
            });

            //const respuesta = await clienteAxios.post('/api/ficha/preguntasObtener');

            //const payload = r01;
            //console.log(payload);

            dispatch({
                type: R01STATE_GUARDAR,
                payload: r01
            });

            dispatch({
                type: SPINNER_OFF
            });

        } catch (error) {
            const alerta = {
                msg: error.response,
                categoria: 'alerta-error'
            }

            dispatch({
                type: FICHA_ERROR,
                payload: alerta
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnPantalla02Ficha = async (r02, r03, r04, r05, r06)  => {
        try {
            dispatch({
                type: SPINNER_ON
            });

            //const respuesta = await clienteAxios.post('/api/ficha/preguntasObtener');

            //const payload = r01;
            //console.log(r02, r03, r04, r05, r06);

            dispatch({
                type: R02STATE_GUARDAR,
                payload: r02
            });

            dispatch({
                type: R03STATE_GUARDAR,
                payload: r03
            });

            dispatch({
                type: R04STATE_GUARDAR,
                payload: r04
            });

            dispatch({
                type: R05STATE_GUARDAR,
                payload: r05
            });

            dispatch({
                type: R06STATE_GUARDAR,
                payload: r06
            });

            dispatch({
                type: SPINNER_OFF
            });

        } catch (error) {
            const alerta = {
                msg: error.response,
                categoria: 'alerta-error'
            }

            dispatch({
                type: FICHA_ERROR,
                payload: alerta
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnPantalla03Ficha = async (r07, r08, r09, r10, r11, r12)  => {
        try {
            dispatch({
                type: SPINNER_ON
            });

            //const respuesta = await clienteAxios.post('/api/ficha/preguntasObtener');

            //const payload = r01;
            //console.log(r02, r03, r04, r05, r06);

            dispatch({
                type: R07STATE_GUARDAR,
                payload: r07
            });

            dispatch({
                type: R08STATE_GUARDAR,
                payload: r08
            });

            dispatch({
                type: R09STATE_GUARDAR,
                payload: r09
            });

            dispatch({
                type: R10STATE_GUARDAR,
                payload: r10
            });

            dispatch({
                type: R11STATE_GUARDAR,
                payload: r11
            });

            dispatch({
                type: R12STATE_GUARDAR,
                payload: r12
            });

            dispatch({
                type: SPINNER_OFF
            });

        } catch (error) {
            const alerta = {
                msg: error.response,
                categoria: 'alerta-error'
            }

            dispatch({
                type: FICHA_ERROR,
                payload: alerta
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnPantalla04Ficha = async (r13, r14, r15, r16, r17)  => {
        try {
            dispatch({
                type: SPINNER_ON
            });

            //const respuesta = await clienteAxios.post('/api/ficha/preguntasObtener');

            //const payload = r01;
            //console.log(r02, r03, r04, r05, r06);
            dispatch({
                type: R13STATE_GUARDAR,
                payload: r13
            });

            dispatch({
                type: R14STATE_GUARDAR,
                payload: r14
            });

            dispatch({
                type: R15STATE_GUARDAR,
                payload: r15
            });

            dispatch({
                type: R16STATE_GUARDAR,
                payload: r16
            });
            
            dispatch({
                type: R17STATE_GUARDAR,
                payload: r17
            });

            dispatch({
                type: SPINNER_OFF
            });

        } catch (error) {
            const alerta = {
                msg: error.response,
                categoria: 'alerta-error'
            }

            dispatch({
                type: FICHA_ERROR,
                payload: alerta
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnPantalla05Ficha = async (r18, r19, r20)  => {
        try {
            dispatch({
                type: SPINNER_ON
            });

            //const respuesta = await clienteAxios.post('/api/ficha/preguntasObtener');

            //const payload = r01;
            //console.log(r02, r03, r04, r05, r06);
            dispatch({
                type: R18STATE_GUARDAR,
                payload: r18
            });

            dispatch({
                type: R19STATE_GUARDAR,
                payload: r19
            });

            dispatch({
                type: R20STATE_GUARDAR,
                payload: r20
            });

            dispatch({
                type: SPINNER_OFF
            });

            //fnPantallaSubmit();

        } catch (error) {
            const alerta = {
                msg: error.response,
                categoria: 'alerta-error'
            }

            dispatch({
                type: FICHA_ERROR,
                payload: alerta
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnPantallaSubmit = async ()  => {
        const {r01Ficha, r02Ficha, r03Ficha, r04Ficha, r05Ficha
            , r06Ficha, r07Ficha, r08Ficha, r09Ficha, r10Ficha
            , r11Ficha, r12Ficha, r13Ficha, r14Ficha, r15Ficha
            , r16Ficha, r17Ficha, r18Ficha, r19Ficha, r20Ficha} = state;
        //console.log(r01Ficha, r02Ficha, r03Ficha);

        try {
            dispatch({
                type: SPINNER_ON
            });

            const tipoGuardar = 'LOG-014'

            const respuesta = await clienteAxios.post('/api/ficha/preguntasEnviarFinalizado', {tipoGuardar,
                r01Ficha, r02Ficha, r03Ficha, r04Ficha, r05Ficha,
                r06Ficha, r07Ficha, r08Ficha, r09Ficha, r10Ficha,
                r11Ficha, r12Ficha, r13Ficha, r14Ficha, r15Ficha,
                r16Ficha, r17Ficha, r18Ficha, r19Ficha, r20Ficha,});
            //console.log(respuesta);

            const payload = await respuesta.data.recordsets[1][0];
            //console.log(respuesta.data);
            if(respuesta.data.recordsets[0][0].id_status === 201){    
                dispatch({
                    type: FICHA_GUARDAROK,
                    payload: payload
                });
            }else{
                
                const alerta = {
                    msg: payload.msg,
                    categoria: 'alerta-error'
                }
    
                dispatch({
                    type: FICHA_ERROR,
                    payload: alerta
                })
            }
            

            dispatch({
                type: SPINNER_OFF
            });

        } catch (error) {
            const alerta = {
                msg: error.response,
                categoria: 'alerta-error'
            }

            dispatch({
                type: FICHA_ERROR,
                payload: alerta
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    return (
        <FichaContext.Provider
            value= {{
                mensaje: state.mensaje,
                loading: state.loading,
                preguntasFicha: state.preguntasFicha,
                pantallasFicha: state.pantallasFicha,
                areaPadreFicha: state.areaPadreFicha,
                areaHijoFicha: state.areaHijoFicha,
                areaHijoSeleccion: state.areaHijoSeleccion,
                sectorFicha: state.sectorFicha,
                tamanioFicha: state.tamanioFicha,
                plataformaCorreoFicha: state.plataformaCorreoFicha,
                basePropiaFicha: state.basePropiaFicha,
                r01Ficha: state.r01Ficha,
                r02Ficha: state.r02Ficha,
                r03Ficha: state.r03Ficha,
                r04Ficha: state.r04Ficha,
                r05Ficha: state.r05Ficha,
                r06Ficha: state.r06Ficha,
                r07Ficha: state.r07Ficha,
                r08Ficha: state.r08Ficha,
                r09Ficha: state.r09Ficha,
                r10Ficha: state.r10Ficha,
                r11Ficha: state.r11Ficha,
                r12Ficha: state.r12Ficha,
                r13Ficha: state.r13Ficha,
                r14Ficha: state.r14Ficha,
                r15Ficha: state.r15Ficha,
                r16Ficha: state.r16Ficha,
                r17Ficha: state.r17Ficha,
                r18Ficha: state.r18Ficha,
                r19Ficha: state.r19Ficha,
                r20Ficha: state.r20Ficha,
                respuestas: state.respuestas,
                execEnviarFinalizado: state.execEnviarFinalizado,
                fnPreguntasObtener: fnPreguntasObtener,
                fnSeleccionAreaHijo: fnSeleccionAreaHijo,
                fnPantalla01Ficha: fnPantalla01Ficha,
                fnPantalla02Ficha: fnPantalla02Ficha,
                fnPantalla03Ficha: fnPantalla03Ficha,
                fnPantalla04Ficha: fnPantalla04Ficha,
                fnPantalla05Ficha: fnPantalla05Ficha,
                fnPantallaSubmit: fnPantallaSubmit,
                fnRespuestasObtener: fnRespuestasObtener

            }}
        >{props.children}

        </FichaContext.Provider>
    )
}

export default FichaState;