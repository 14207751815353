import React, {Fragment} from 'react';

const PantallaAreaHijo = ({selAreaHijo, setSelAreaHijo,preguntasFicha, opcAreaHijo}) => {
    

    const handleOnChange = (item, checked) => {
        //console.log('click hijo');
        if(checked){
            //console.log('verdadero');
            const nuevo = {id: item.id_agrupacion, valor: checked, f_valor: (checked === true) ? 1 : 0}
            setSelAreaHijo([...selAreaHijo, nuevo]);
        }else{
            //console.log('falso');
            const seleccion2 = selAreaHijo.filter(x => (x.id !== item.id_agrupacion && x.valor === true));
            //console.log(seleccion2);
            setSelAreaHijo(seleccion2);
        }
    }

    return (
        <fieldset>
            <div className='form-group'>
                <h5>2.3- {preguntasFicha[3].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[3].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[3].desc_fip_preguntaT3}</h6>
                <fieldset>
                    {opcAreaHijo.map((item, index) => {
                        //console.log(item[0].desc_agrupacion_corta);
                        return (
                            <Fragment key={index}>
                            <h5 className='m-2 c-rojoEP'>{item[0].desc_agrupacion_corta2}</h5>
                            {item.map((item, index) => [
                                <div className='form-check form-switch' key={item.id_agrupacion} >
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id={item.id_agrupacion}
                                        //onClick={() => handleOnChange(item.id_agrupacion_corta)}
                                        //checked={checkedState[index]}
                                        onChange={(e) => handleOnChange(item, e.target.checked)}
                                        //defaultValue={defaultValue}
                                        //disabled={(item.estado === 'false' ? true : false)}
                                    />
                                    <label className='form-check-label' htmlFor={item.id_agrupacion}>{item.desc_agrupacion}</label>
                                </div>
                            ])}
                            </Fragment>
                        )
                    })}
                </fieldset>
                {/*<textarea name='resp1' className='form-control' rows='1' defaultValue={resp1} onChange={setForm}></textarea>*/}
            </div>
        </fieldset>

    )
}

export default PantallaAreaHijo;
