import React from 'react';


const ReporteMensualTablaClientesContactos = ({rmDetalleContacto6MSeleccion}) => {

  return (
    <>
    <table className='table table-hover table-responsive table-sm thead6m'>
        <thead>
            <tr>
                <th scope='col'>Area</th>
                <th scope='col'>Contacto</th>
                <th scope='col'>Email</th>
            </tr>
        </thead>
        <tbody>
            {(rmDetalleContacto6MSeleccion) ?
                rmDetalleContacto6MSeleccion.map((item, index) => {
                return (
                    <tr className='table-info' key={index}>
                        <td className='text-center'>{item.desc_prc_area}</td>
                        <td className='text-center'>{item.desc_prc_contacto_largo}</td>
                        <td className='text-center'>{item.desc_prc_email}</td>
                    </tr>
                    );
                })
            : null}
        </tbody>
    </table>
    </>
  )
}

export default ReporteMensualTablaClientesContactos;