import React, { Fragment } from 'react';
import RutaPrivada from './components/private/RutaPrivada';
import LoginSesion from './components/auth/LoginSesion';
import Configuracion from './components/auth/configuracion/Configuracion';
import ReporteMensual from './components/layout/pages/reporteMensual/ReporteMensual';
//import Dashboard from './components/layout/pages/dashboard/Dashboard';
import Servicio from './components/layout/pages/servicio/Servicio';
import Resultados from './components/layout/pages/resultados/Resultados';

import AuthState from './context/autentication/authState';  
import AlertaState from './context/alerta/alertaState';
import ServicioState from './context/servicio/servicioState';
import ResultadosState from './context/resultados/resultadosState';
import EmailState from './context/email/emailState';
import FichaState from './context/ficha/fichaState';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import tokenAuth from './config/token';

//Paginas de Prueba
//import Home from './pages/Home';
//import Reports from './pages/Reports';
import Products from './pages/Products';


//Revisar si hay token en el navegador
const token = localStorage.getItem('token');
if(token) {
  tokenAuth(token);
}

function App() {
  return (
    <Fragment>
      <AlertaState>
        <AuthState>
          <ServicioState>
            <ResultadosState>
              <EmailState>
                <FichaState>
                  <Router>
                    <Switch>
                      <Route exact path='/' component={LoginSesion} />
                      <RutaPrivada exact path='/reporte' component={ReporteMensual} />
                      {/*<RutaPrivada exact path='/indicadores' component={Dashboard} />*/}
                      <RutaPrivada exact path='/indicadores' component={ReporteMensual} />
                      <RutaPrivada path='/resultados' component={Resultados} />
                      <RutaPrivada path='/notificacion' component={Products} />
                      <RutaPrivada path='/configuracion' component={Configuracion} />
                      <RutaPrivada path='/mi-prospeccion' component={Servicio} />
                    </Switch>
                  </Router>
                </FichaState>
              </EmailState>
            </ResultadosState>
          </ServicioState>
        </AuthState>
      </AlertaState>
    </Fragment>
  );
}

export default App;
