import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';
import tokenAuth from '../../config/token';
import ResultadosContext from './resultadosContext';
import ResultadosReducer from './resultadosReducer';

import 
{
    SPINNER_ON,
    SPINNER_OFF,
    CERRAR_SESION,
    RESULTADOS_ERROR,
    OPORTUNIDADES_OBTENER,
    SINFORMACION_OBTENER,
    DETALLEAPERTURA_OBTENER,
    LANDING_OBTENER,
    VALORACION_ENVIAR,
    //VALORACION_OBTENER
}
from '../../types';

const ResultadosState = props => {
    const initialState = {
        oportunidades: null,
        sinformacion: null,
        detalleAperturas: null,
        loadingSpinner: false
    }

    const [state, dispatch] = useReducer(ResultadosReducer, initialState);


    /*const fnOportunidades = async () => {
        const token = localStorage.getItem('token');
        if(token){
            //Envia el Token por Headers
            tokenAuth(token);
            //console.log('recargando');
        }

        try {

            dispatch({
                type: SPINNER_ON
            }); 

            const respuesta = await  clienteAxios.post('/api/resultados/oportunidades');
            //console.log(respuesta.data.recordset[0]);

            dispatch({
                type: OPORTUNIDADES_OBTENER,
                payload: respuesta.data.recordset[0]
            });

            dispatch({
                type: SPINNER_OFF
            }); 

        } catch (error) {
            console.log(error);
            dispatch({
                type: RESULTADOS_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }*/
    const fnDetalleApertura = async () => {
        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await  clienteAxios.post('/api/resultados/detalleApertura');
            //console.log(respuesta.data.recordset);

            dispatch({
                type: DETALLEAPERTURA_OBTENER,
                payload: respuesta.data.recordset
            });

            dispatch({
                type: SPINNER_OFF
            });
            
        } catch (error) {
            console.log(error);
            dispatch({
                type: RESULTADOS_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnOportunidades = async () => {
        const token = localStorage.getItem('token');
        if(token){
            //Envia el Token por Headers
            tokenAuth(token);
            //console.log('recargando');
        }
        
        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await  clienteAxios.post('/api/resultados/oportunidades');
            //console.log(respuesta.data.recordset);

            dispatch({
                type: OPORTUNIDADES_OBTENER,
                payload: respuesta.data.recordset
            });

            /*if(respuesta.data.recordset.length > 0){
                await fnEmailOportunidades(respuesta.data.recordset);
            }*/
            
            dispatch({
                type: SPINNER_OFF
            });

            
        } catch (error) {
            console.log(error);
            dispatch({
                type: RESULTADOS_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnSinformacion = async () => {
        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await  clienteAxios.post('/api/resultados/sinformacion');
            //console.log(respuesta.data.recordset);

            dispatch({
                type: SINFORMACION_OBTENER,
                payload: respuesta.data.recordset
            });

            dispatch({
                type: SPINNER_OFF
            });

            
        } catch (error) {
            console.log(error);
            dispatch({
                type: RESULTADOS_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    /*const fnEmailOportunidades = async (datos) => {
        try {

            //await clienteAxios.post('/api/emailSend/EmailOportunidades', datos);
            const respuesta = await clienteAxios.post('/api/emailSend/EmailOportunidades', datos);
            console.log(respuesta);
            dispatch({
                type: EMAIL_OPORTUNIDADES,
                payload: respuesta.data.recordset
            });
                
        } catch (error) {
            console.log(error);
            dispatch({
                type: EMAIL_ERROR
            });
        }
    }*/

    const fnLanding = async () => {
        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await  clienteAxios.post('/api/resultados/landing');
            //console.log(respuesta.data.recordset);

            dispatch({
                type: LANDING_OBTENER,
                payload: respuesta.data.recordset
            });

            dispatch({
                type: SPINNER_OFF
            });
            
        } catch (error) {
            console.log(error);
            dispatch({
                type: RESULTADOS_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    
    const fnValoracionEnviar = async (rating, ratingTxt) => {
        const datos = ({
            desc_opo_rating: rating,
            desc_opo_ratingTxt: ratingTxt,
        })
        console.log(datos);

        const token = localStorage.getItem('token');
        if(token){
            //Envia el Token por Headers
            tokenAuth(token);
            //console.log('CambiandoContraseña');
        }
        
        try {
            dispatch({
                type: SPINNER_ON
            }); 

            const respuesta = await  clienteAxios.post('/api/servicio/guardarValoracion', datos);
            //console.log(respuesta.data.recordset[0]);

            if(respuesta.data.recordset[0].id_status === 401){
                //console.log('pasando por 401')
                const alerta = {
                    msg: respuesta.data.recordset[0].msg,
                    categoria: 'alerta-error'
                }

                dispatch({
                    type: RESULTADOS_ERROR,
                    payload: alerta
                });

            }else {
                //console.log(respuesta.data.recordset[0].msg);
                const alerta = {
                    msg: respuesta.data.recordset[0].msg,
                    categoria: 'alerta-ok'
                }

                dispatch({
                    type: VALORACION_ENVIAR,
                    payload: alerta
                });
                
                //Consulta Funcion y obtiene resultados de valoraciones de las oportunidades
                //fnHistorialMensajes();
                
            }

            dispatch({
                type: SPINNER_OFF
            }); 

        } catch (error) {
            //console.log(error);
            dispatch({
                type: RESULTADOS_ERROR
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
        
    }

    const fnServicioCerrarSesion = async () => {
        //console.log('click en cerrar sesion');
        dispatch({
            type: CERRAR_SESION
        })
    }

    return (
        <ResultadosContext.Provider
            value= {{
                oportunidades: state.oportunidades,
                sinformacion: state.sinformacion,
                detalleAperturas: state.detalleAperturas,
                detalleLanding: state.detalleLanding,
                loadingSpinner: state.loadingSpinner,
                fnOportunidades: fnOportunidades,
                fnDetalleApertura: fnDetalleApertura,
                fnServicioCerrarSesion: fnServicioCerrarSesion,
                fnSinformacion: fnSinformacion,
                fnLanding: fnLanding,
                fnValoracionEnviar: fnValoracionEnviar
            }}
        >
            {props.children}
        </ResultadosContext.Provider>
    )
}

export default ResultadosState;