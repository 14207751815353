import React, {useState} from 'react';

const PantallaPlataformaCorreo = ({plataformaCorreoFicha, preguntasFicha, setSelPlataformaCorreo, selPlataformaCorreo}) => {
    const [disable, setDisable] = useState(true);

    const handleOnChange = (item, checked) => {
        //console.log(item, checked);
        (item.id_agrupacion === 'PPC-001' || item.id_agrupacion === 'PPC-002') ? setDisable(true) :setDisable(false);
        
        const link = plataformaCorreoFicha.filter(x => (x.id_agrupacion === item.id_agrupacion));
        const nuevo = {id: item.id_agrupacion,
            link: link[0].desc_agrupacion_resp,
            valor: checked,
            f_valor: (checked === true) ? 1 : 0
        }
        
        setSelPlataformaCorreo(nuevo);

    };
    
    const handleOnChangeLink = (e) => {
        //console.log(e);
        const nuevo = {...selPlataformaCorreo,
            link: e.toUpperCase()
        }
        
        setSelPlataformaCorreo(nuevo);
    }
      
    //console.log(seleccion);
    return (
        <fieldset>
            <div className='form-group'>
                <h5>4.5- {preguntasFicha[15].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[15].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[15].desc_fip_preguntaT3}</h6>
                <fieldset>
                    {plataformaCorreoFicha.map((item, index) => {
                        //console.log(item);
                        return (
                            <div className='form-check' key={index}>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    name={`RadioPlataformaCorreo`}
                                    id={item.id_agrupacion}
                                    value={item.id_agrupacion}
                                    onChange={(e) => handleOnChange(item, e.target.checked)}
                                />
                                <label
                                    className='form-check-label'
                                    htmlFor={item.id_agrupacion}
                                >{item.desc_agrupacion}
                                </label>
                            </div>
                        )
                    })}
                    <input
                        className='form-text-input'
                        type='text'
                        //id={item.id_agrupacion}
                        defaultValue={selPlataformaCorreo.desc_agrupacion_resp}
                        onChange={(e) => handleOnChangeLink(e.target.value)}
                        disabled={disable}
                    />
                </fieldset>
                {/*<textarea name='resp1' className='form-control' rows='1' defaultValue={resp1} onChange={setForm}></textarea>*/}
            </div>
        </fieldset>

    )
}

export default PantallaPlataformaCorreo;
