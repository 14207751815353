import React from 'react';
import Asuntos from './Asuntos';
import Mensajes from './Mensajes';
import Parametros from './Parametros';
//import Informacion from './Informacion'
//import Modal from '../../comunes/Modal';
import { 
  faBullhorn
  , faEnvelopeOpenText
  , faCrosshairs
  //, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

/* Links y Rutas Sidebar (Barra Vertical) */
export const ServicioNavTabs = [
  {
    title: 'Asunto',
    path: '#Asunto',
    icon: faBullhorn,
    cNameLi: 'nav-item',
    cNameA: 'nav-link active',
    dataToggle: 'tab'
  },
  {
    title: 'Mensaje',
    path: '#Mensaje',
    icon: faEnvelopeOpenText,
    cNameLi: 'nav-item',
    cNameA: 'nav-link',
    dataToggle: 'tab'
  },
  {
    title: 'Parámetros',
    path: '#Parametros',
    icon: faCrosshairs,
    cNameLi: 'nav-item',
    cNameA: 'nav-link',
    dataToggle: 'tab'
  }/*,
  {
    title: 'Información',
    path: '#Informacion',
    icon: faInfoCircle,
    cNameLi: 'nav-item',
    cNameA: 'nav-link',
    dataToggle: 'tab'
  }*/
];

export const ServicioTabContent = [
    {
      id: 'Asunto',
      path: '#Asunto',
      component: <Asuntos />,
      cName: 'tab-pane fade active show',
    },
    {
      id: 'Mensaje',
      path: '#Mensaje',
      component: <Mensajes />,
      cName: 'tab-pane fade',
    },
    {
      id: 'Parametros',
      path: '#Parametros',
      component: <Parametros />,
      cName: 'tab-pane fade',
    }/*,
    {
      id: 'Informacion',
      path: '#Informacion',
      component: <Informacion />,
      cName: 'tab-pane fade',
    }*/
  ];