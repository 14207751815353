import React, {useState, useContext} from 'react';
import PantallaSiNo from './PantallaSiNo';
import PantallaPlataformaCorreo from './PantallaPlataformaCorreo';

import FichaContext from '../../../../../context/ficha/fichaContext';
import AlertaContext from '../../../../../context/alerta/alertaContext';


const Pantalla4 = ({formData, setForm, navigation, preguntasFicha}) => {
    const {resp13, resp14, resp15} = formData
    
    const fichaContext = useContext(FichaContext);
    const { basePropiaFicha, plataformaCorreoFicha, fnPantalla04Ficha } = fichaContext;

    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;

    const [selBasePropia, setSelBasePropia] = useState([]);
    const [selPlataformaCorreo, setSelPlataformaCorreo] = useState([]);
    

    const clickGuardarContinuar = (() => {
        //console.log(selTamanio);
        if (resp13 === '' || resp14 === '' || resp15 === '' || selBasePropia.length === 0 || selPlataformaCorreo.length === 0 || selPlataformaCorreo.link.length === 0){
            mostrarAlerta('¡Debe responder todas las preguntas!', 'alerta-error');
            return;
        }
        
        fnPantalla04Ficha(resp13.replace(/(\r\n|\n|\r)/g, '<br />'),
            resp14.replace(/(\r\n|\n|\r)/g, '<br />'),
            resp15.replace(/(\r\n|\n|\r)/g, '<br />'),
            selPlataformaCorreo,
            selBasePropia
        );
        navigation.next()
    });

    return (
        <div>
            { alerta? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null }
            <div className='form-group'>
                <h5>4.1- {preguntasFicha[12].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[12].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[12].desc_fip_preguntaT3}</h6>
                <textarea name='resp13' className='form-control' rows='1' defaultValue={resp13} onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>4.2- {preguntasFicha[13].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[13].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[13].desc_fip_preguntaT3}</h6>
                <textarea name='resp14' className='form-control' rows='1' defaultValue={resp14} onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>4.3- {preguntasFicha[14].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[14].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[14].desc_fip_preguntaT3}</h6>
                <textarea name='resp15' className='form-control' rows='1' defaultValue={resp15} onChange={setForm}></textarea>
            </div>

            <PantallaPlataformaCorreo
                preguntasFicha = {preguntasFicha}
                plataformaCorreoFicha = {plataformaCorreoFicha}
                setSelPlataformaCorreo = {setSelPlataformaCorreo}
                selPlataformaCorreo = {selPlataformaCorreo}
            />

            <PantallaSiNo
                preguntasFicha = {preguntasFicha}
                basePropiaFicha = {basePropiaFicha}
                setSelBasePropia = {setSelBasePropia}
                selBasePropia = {selBasePropia}
            />

            <button
                className='btn btn-primario col-2 mt-4'
                onClick={() => clickGuardarContinuar()}
            >Siguiente
            </button>
            <button
                className='btn btn-secundario col-2 mt-4'
                onClick={() => navigation.previous()}
            >Volver
            </button>
                {/*<Button
                    variant='contained'
                    color='primary'
                    style={{marginTop:'1rem'}}
                    onClick={() => navigation.next()}
                >Siguiente
                </Button>*/}
        </div>
    )
}

export default Pantalla4;
