import React, {useContext} from 'react';
import FichaContext from '../../../../../context/ficha/fichaContext';

const RespuestasFicha = () => {
    const fichaContext = useContext(FichaContext);
    const { respuestas } = fichaContext;

    return(
        <>
                <div className='card-header'><h4>Respuestas Ficha Diagnostico</h4></div>
                <div className='card-body'>
                    {respuestas.map((item, index) => (
                        /*<div class='bd-callout bd-callout-warning'>
                            <h6>{item.desc_fip_preguntaT1}</h6>
                            <p>{item.desc_fir_respuesta_agr}</p>
                        </div>*/
                        <div className='container col-sm-9' key={index}>
                            <div className='card border-danger mb-3'>
                            {(item.indiceGrupo === '1') ?
                                <div className='card-header'>
                                    <h5 className='azulEP'>{item.desc_fip_preguntaT1}</h5>
                                </div>
                                : null}
                                <div className='card-body'>
                                    <li className='ml-3' dangerouslySetInnerHTML = {{__html: item.desc_fir_respuesta_agr}}></li>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
        </>
    );
};

export default RespuestasFicha;
