import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';
//import tokenAuth from '../../config/token';
import EmailContext from './emailContext';
import EmailReducer from './emailReducer';

import 
{
    SPINNER_ON,
    SPINNER_OFF,
    EMAIL_ITEMAPROBADO,
    EMAIL_ERROR
}
from '../../types';

const EmailState = props => {
    const initialState = {
        loadingSpinner: false
    }

    const [state, dispatch] = useReducer(EmailReducer, initialState);

    const fnFichaEnviarFinalizado = async (execEnviarFinalizado) => {
        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await  clienteAxios.post('/api/emailSend/FichaEnviarFinalizado', {execEnviarFinalizado});

            //const respuesta = await  clienteAxios.post('/api/emailSend/SolicitudAceptada');
            console.log(respuesta);

            dispatch({
                type: EMAIL_ITEMAPROBADO,
                payload: respuesta.data.recordset
            });

            dispatch({
                type: SPINNER_OFF
            });
            
        } catch (error) {
            console.log(error);
            dispatch({
                type: EMAIL_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnFichaEnviarFinalizadoInterno = async (execEnviarFinalizado) => {
        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await  clienteAxios.post('/api/emailSend/FichaEnviarFinalizadoInterno', {execEnviarFinalizado});

            //const respuesta = await  clienteAxios.post('/api/emailSend/SolicitudAceptada');
            console.log(respuesta);

            dispatch({
                type: EMAIL_ITEMAPROBADO,
                payload: respuesta.data.recordset
            });

            dispatch({
                type: SPINNER_OFF
            });
            
        } catch (error) {
            console.log(error);
            dispatch({
                type: EMAIL_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }


    return (
        <EmailContext.Provider
            value= {{
                loadingSpinner: state.loadingSpinner,
                fnFichaEnviarFinalizado: fnFichaEnviarFinalizado,
                fnFichaEnviarFinalizadoInterno: fnFichaEnviarFinalizadoInterno
            }}
        >
            {props.children}
        </EmailContext.Provider>
    )
}

export default EmailState;