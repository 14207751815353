import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTimes
  , faSearchDollar
  , faUsersCog
  ,faFileContract
  , faChartBar
  //, faClipboard
} from '@fortawesome/free-solid-svg-icons';


/* Links y Rutas Sidebar (Barra Vertical) */
export const SidebarData = [
  {
    title: '',
    path: '/',
    icon: <FontAwesomeIcon icon={faTimes} size='lg' />,
    cName: 'nav-text navbar-toggle'
  },
  {
    title: 'Indicadores',
    path: '/indicadores',
    icon: <FontAwesomeIcon icon={faChartBar} size='lg' />,
    cName: 'nav-text'
  }/*,
  {
    title: 'Reporte Mensual',
    path: '/reporte',
    icon: <FontAwesomeIcon icon={faClipboard} size='lg' />,
    cName: 'nav-text'
  }*/,
  {
    title: 'Mi Prospección',
    path: '/mi-prospeccion',
    icon: <FontAwesomeIcon icon={faFileContract} size='lg' />,
    cName: 'nav-text'
  },
  {
    title: 'Resultados',
    path: '/resultados',
    icon: <FontAwesomeIcon icon={faSearchDollar} size='lg' />,
    cName: 'nav-text'
  }/*,
  {
    title: <Fragment>Notificaciones <span className='badge badge-pill badge-danger'>4</span> </Fragment>,
    path: '/notificacion',
    icon: <Fragment><FontAwesomeIcon icon={faBell} size='lg'/></Fragment>,
    cName: 'nav-text small'
  }*/,
  {
    title: 'Configuración',
    path: '/configuracion',
    icon: <FontAwesomeIcon icon={faUsersCog} size='lg' />,
    cName: 'nav-text small'
  }/*,
  {
    title: 'Cerrar Sesión',
    path: '/cerrarSesion',
    icon: <FontAwesomeIcon icon={faSignOutAlt} size='lg' />,
    cName: 'nav-text small'
  }*/
];

/* Links y Rutas Nav-Header (Barra Horizontal) */
export const NavbarData = [
  /*{
    title: 'Notificaciones',
    path: '/notificacion',
    icon: <Fragment><span className='badge badge-pill badge-danger bar-notify'>4</span>
    <FontAwesomeIcon className='nav-menu-icon' icon={faBell} size='lg'/></Fragment>,
    cName: 'nav-text small'
    
  },*/
  {
    title: 'Configuración',
    path: '/configuracion',
    icon: <Fragment><FontAwesomeIcon className='nav-menu-icon' icon={faUsersCog} size='lg'/></Fragment>,
    cName: 'nav-text small'
  }/*,
  {
    title: 'Cerrar Sesión',
    path: '/cerrarSesion',
    icon: <Fragment><FontAwesomeIcon className='nav-menu-icon' icon={faSignOutAlt} size='lg'/></Fragment>,
    cName: 'nav-text small'
  }*/
];