import React, {useContext, useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import Spinner from '../../../../layout/spinner/Spinner';

import FichaContext from '../../../../../context/ficha/fichaContext';
import AlertaContext from '../../../../../context/alerta/alertaContext';
import EmailContext from '../../../../../context/email/emailContext';

const Submit = (props) => {
    //console.log(props);
    const [enviarDatos, setEnviarDatos] = useState(false);

    const fichaContext = useContext(FichaContext);
    const { execEnviarFinalizado, fnPantallaSubmit } = fichaContext;

    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;

    const emailContext = useContext(EmailContext);
    const { loading, fnFichaEnviarFinalizado } = emailContext;

    
    useEffect(() => {
        

        //console.log('effect');
        (async () => {
            if (enviarDatos === true) return;

            //console.log('fnPantallaSubmit');
            await fnPantallaSubmit();
            setEnviarDatos(false);
        })();

        // eslint-disable-next-line
      }, [execEnviarFinalizado]);

      useEffect(() => {
        if(execEnviarFinalizado.id_status === 201){
            //console.log('201');
            mostrarAlerta('Ficha Enviada', 'alerta-ok');

            fnFichaEnviarFinalizado(execEnviarFinalizado);
            //fnFichaEnviarFinalizadoInterno(execEnviarFinalizado);

            setTimeout(() => {
                props.history.push('/indicadores');
            }, 5000);
        }else if(execEnviarFinalizado.id_status === 401){
            //console.log('401');
            mostrarAlerta('Error al enviar la Ficha', 'alerta-error');
        }
        
        // eslint-disable-next-line
      }, [execEnviarFinalizado.id_status])


    

    return (
        <div>
            { alerta? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null }
            {(loading === true) ? (<div className='spinner'><Spinner/></div>) : null}
            <h5 className='text-center'>
                <FontAwesomeIcon icon={faTrophy} size='lg'/> <FontAwesomeIcon icon={faThumbsUp} size='lg'/>
                <p className='parrafo text-center'>Gracias por tu tiempo.<span className='c-rojoEP'> Espere unos segundos mientras es redirigido</span></p>
                <p className='parrafo text-center'>Pronto serás contactado por nuestro equipo.</p>
                <br />
                {/*<button
                    className='btn btn-primario col-2 mt-4'
                    onClick={() => clickFinalizar()}
                >Enviar y Finalizar
                </button>

                <button
                    className='btn btn-secundario col-2 mt-4'
                    //onClick={() => navigation.previous()}
                >Volver
                </button>*/}
            </h5>
            
            
        </div>
    )
}

export default withRouter(Submit);
