import React, { Fragment, useContext, useEffect } from 'react';
import { Route, Redirect,  } from 'react-router-dom';
import Navbar from '../layout/navBar/Navbar';
import AuthContext from '../../context/autentication/authContext';

const RutaPrivada = ({ component: Component, ...props }) => {

    const authContext = useContext(AuthContext);
    const { autenticado, cargando, usuarioAutenticado } = authContext;

    useEffect(() => {
        usuarioAutenticado();
        // eslint-disable-next-line
    }, []);
  

    return (
        <Route
            {...props} render={props => !autenticado && !cargando ? (
                <Redirect to='/'/>
            ) : (
                <Fragment>
                    <Navbar />
                    <Component {...props}/>
                    <div className='footerNav'>Portal de clientes El Prospector v3.0</div>
                </Fragment>
            )}
        />
    )
}

export default RutaPrivada;
