import React, { useContext, useEffect, useState, useRef } from 'react';
import ServicioContext from '../../../../context/servicio/servicioContext';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTimesCircle, faCheckCircle
  } from '@fortawesome/free-solid-svg-icons';

const AsuntosNuevos = () => {
    //Context
    const servicioContext = useContext(ServicioContext);
    const { asuntosUsados, fnObtieneAsuntos, fnAsuntoCrear } = servicioContext;

    //State
    const [desc_asu_asunto, setDesc_asu_asunto ] = useState({
        desc_asu_asunto:'',
    });
    const [caracterRest, setCaracterRest] = useState(120);
    const [btnDisableAsu, setBtnDisableAsu] = useState(false);
    const [badgeCaracterRest, setBadgeCaracterRest] = useState('info');
    const [badgeValidaEmpresa, setBadgeValidaEmpresa] = useState({
        icono: faTimesCircle,
        claseIcono: 'KO',
        texto: 'Nombre de Empresa NO ingresado'

    });
    const [badgeValidaFirstName, setBadgeValidaFirstName] = useState({
        icono: faTimesCircle,
        claseIcono: 'KO',
        texto: 'Nombre de Contacto NO ingresado'

    });
    
    //Variables
    const caracterMax = 120;
    const ContactEmpresa = '~Contact.Company~';//'~Contact._Empresa~';
    const ContactFirstName = '~Contact.FirstName~';

    //Refs
    const desc_asu_asuntoRef = useRef('');

    useEffect( () => {
        if(asuntosUsados) return;
        
        fnObtieneAsuntos();
        // eslint-disable-next-line
    }, [asuntosUsados]);

    
    //Funciones
    const OnChangeAsunto = e =>{
        e.preventDefault();
        //console.log([e.target.value]);
        setDesc_asu_asunto({
            [e.target.name] : [e.target.value]
        });
        largoCadena(e.target.value);
    }

    const largoCadena = (cadena) => {
        //console.log(e);
        setCaracterRest(caracterMax - cadena.length);

        if( (caracterMax - cadena.length) < 0){
            setBadgeCaracterRest('dark');
            setBtnDisableAsu(true);
        }else if( (caracterMax - cadena.length) < 10){
            setBadgeCaracterRest('danger');
            setBtnDisableAsu(false);
        }else if( (caracterMax - cadena.length) < 25){
            setBadgeCaracterRest('warning');
            setBtnDisableAsu(false);
        }else if( (caracterMax - cadena.length) <= caracterMax){
            setBadgeCaracterRest('info');
            setBtnDisableAsu(false);
        }

        
        const asuntoValidado = (cadena.indexOf(ContactEmpresa) > -1)
        //console.log(asuntoValidado);
        if(!asuntoValidado){
            setBadgeValidaEmpresa({
                icono: faTimesCircle,
                claseIcono: 'KO',
                texto: 'Nombre de Empresa NO ingresado'
            });

        }else{
            setBadgeValidaEmpresa({
                icono: faCheckCircle,
                claseIcono: 'OK',
                texto: 'Nombre de Empresa ingresado'
            });
        }

        const asuntoValidadoContacto = (cadena.indexOf(ContactFirstName) > -1)
        //console.log(asuntoValidado);
        if(!asuntoValidadoContacto){
            setBadgeValidaFirstName({
                icono: faTimesCircle,
                claseIcono: 'KO',
                texto: 'Nombre de Contacto NO ingresado'
            });

        }else{
            setBadgeValidaFirstName({
                icono: faCheckCircle,
                claseIcono: 'OK',
                texto: 'Nombre de Contacto ingresado'
            });
        }

        desc_asu_asuntoRef.current.focus();
    }

    const btnEmpresa = () => {
        //console.log('click Empresa')
        const nuevaCadena = `${desc_asu_asunto.desc_asu_asunto} ${ContactEmpresa} `;
        setDesc_asu_asunto({desc_asu_asunto: nuevaCadena});
        largoCadena(nuevaCadena);
    }

    const btnContacto = () => {
        //console.log('click Empresa')
        const nuevaCadena = `${desc_asu_asunto.desc_asu_asunto} ${ContactFirstName} `;
        setDesc_asu_asunto({desc_asu_asunto: nuevaCadena});
        largoCadena(nuevaCadena);
    }
    

    const onSubmitAsunto = () =>{
        //e.preventDefault();
        //console.log(desc_asu_asunto);
        Swal.fire({
            title: 'Revisión Final',
            text: '¿Estas seguro de utilizar este nuevo asunto?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#034673',
            cancelButtonColor: '#555655',
            confirmButtonText: 'Si, enviar',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
                fnAsuntoCrear(desc_asu_asunto);        
                Swal.fire(
                    '¡Solicitud nuevo asunto enviada!',
                    'Será utilizado para la prospección desde la próxima semana',
                    'success'
                )
            }
          })
        
        //fnAsuntoCrear(desc_asu_asunto);
    }
    
    return (
        <div className='container'>
            <div className='card border-info mb-3'>
            <div className='form-group'>
                <label name='validadorCaracterMax' htmlFor='desc_asu_asunto' className='d-block ml-2'>
                    <span className={`badge badge-pill badge-${badgeCaracterRest}`}>{caracterRest}</span> Caracteres Disponibles
                </label>
                <label name='validadorContactEmpresa' className='d-block ml-2'>
                    <FontAwesomeIcon
                        className={`validadorEmpresa-${badgeValidaEmpresa.claseIcono}`}
                        icon={badgeValidaEmpresa.icono}
                        size='lg'
                    /> {badgeValidaEmpresa.texto}
                </label>
                <label name='validadorContactFirstName' className='d-block ml-2'>
                    <FontAwesomeIcon
                        className={`validadorFirstName-${badgeValidaFirstName.claseIcono}`}
                        icon={badgeValidaFirstName.icono}
                        size='lg'
                    /> {badgeValidaFirstName.texto}
                </label>
                <div>
                    <button type="button" className='btnCorto sm btn-naranjo d-inline-flex ml-2' onClick={() => btnEmpresa()}>Insertar Empresa</button>
                    <button type="button" className='btnCorto sm btn-naranjo d-inline-flex ml-2' onClick={() => btnContacto()}>Insertar Contacto</button>
                <input 
                    className='form-control mt-2'
                    id='desc_asu_asunto'
                    name='desc_asu_asunto'
                    type='text'
                    placeholder='Captura mas atención con un nuevo asunto'
                    onChange={OnChangeAsunto}
                    maxLength={caracterMax}
                    value={desc_asu_asunto.desc_asu_asunto}
                    ref={desc_asu_asuntoRef}
                />
                </div>
                <button
                    className='btn btn-primario col-sm-12 mt-4'
                    onClick={() => onSubmitAsunto()}
                    disabled={btnDisableAsu}
                >
                    Enviar Solicitud
                </button>
            </div>
            </div>
        </div>
    )
}

export default AsuntosNuevos;
