import React from 'react';

const PantallaSiNo = ({basePropiaFicha, preguntasFicha, setSelBasePropia, selBasePropia}) => {

    const handleOnChange = (item, checked) => {
        //console.log(item, checked);
        const nuevo = {id: item.id_agrupacion, valor: checked, f_valor: (checked === true) ? 1 : 0}
        setSelBasePropia(nuevo);
    };
      
      
    //console.log(seleccion);
    return (
        <fieldset>
            <div className='form-group'>
                <h5>4.5- {preguntasFicha[16].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[16].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[16].desc_fip_preguntaT3}</h6>
                <fieldset>
                    {basePropiaFicha.map((item, index) => {
                        //console.log(item);
                        return (
                            <div className='form-check' key={index}>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    name={`RadioPantallaSiNo`}
                                    id={item.id_agrupacion}
                                    value={item.id_agrupacion}
                                    onChange={(e) => handleOnChange(item, e.target.checked)}
                                />
                                <label
                                    className='form-check-label'
                                    htmlFor={item.id_agrupacion}
                                >{item.desc_agrupacion}
                                </label>
                            </div>
                        )
                    })}
                </fieldset>
                {/*<textarea name='resp1' className='form-control' rows='1' defaultValue={resp1} onChange={setForm}></textarea>*/}
            </div>
        </fieldset>

    )
}

export default PantallaSiNo;
