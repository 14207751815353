import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointer } from '@fortawesome/free-solid-svg-icons';

const ReporteMensualTablaClientesEmpresas = ({empresaSeleccion, periodoSeleccion, rmDetalleEmpresa6MSeleccion, setEmpresaSeleccion, setPeriodoSeleccion}) => {

    const EmpresaSeleccionada = (e) => {
        //console.log(e.id_prs_prospecto);
        setEmpresaSeleccion(e.id_prs_prospecto);
        setPeriodoSeleccion(e.id_tie_mes);
    }

    return (
    <>
    <table className='table table-hover table-responsive table-sm thead6m'>
        <thead>
            <tr>
                <th scope='col'><FontAwesomeIcon icon={faHandPointer} className='c-azulEP' /></th>
                <th scope='col'>Mes</th>
                <th scope='col'>Prospecto</th>
            </tr>
        </thead>
        <tbody>
            {(rmDetalleEmpresa6MSeleccion) ?
                rmDetalleEmpresa6MSeleccion.map((item, index) => {
                return (
                    <tr className='table-info' key={index}>
                        <td className='text-center'>
                            <div className='input-group'>
                                    <div className='input-group-prepend'>
                                        <div className='input-group-text'>
                                            <input
                                                type='radio'
                                                value={item.id_cli_rut}
                                                checked={( (item.id_prs_prospecto === empresaSeleccion) && (item.id_tie_mes === periodoSeleccion) ) ? true : false}
                                                onChange={()=>(EmpresaSeleccionada(item))}
                                            />
                                        </div>
                                    </div>
                            </div>
                        </td>
                        <td className='text-center'>{item.desc_tie_mes}</td>
                        <td className='text-center'>{item.desc_prs_prospecto}</td>
                    </tr>
                    );
                })
            : null}
        </tbody>
    </table>
    </>
    )
}

export default ReporteMensualTablaClientesEmpresas;