import React, { useContext, useEffect} from 'react';
import Spinner from '../../spinner/Spinner';
//import RatingStar from '../../comunes/RatingStar';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import ResultadosContext from '../../../../context/resultados/resultadosContext';


const Oportunidades = () => {
    const resultadosContext = useContext(ResultadosContext);
    const { loadingSpinner, oportunidades, fnOportunidades } = resultadosContext;


    //const { desc_dis_prospecto } = oportunidades || {}
    useEffect( () => {
        if(oportunidades) return;
        
        fnOportunidades();
        // eslint-disable-next-line
    }, [oportunidades]);

    const handleOportunidadDetalle = (item) => {
        //console.log(item);
        try {
            Swal.fire({
                title: item.desc_prc_contacto_largo,
                text: item.desc_prc_email,
                footer: `Gerencia: ${item.desc_prc_area}`,
                confirmButtonColor: '#034673',
                confirmButtonText: 'Volver',
              })
          } catch (error) {
            console.log(error);
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Volver a Intentar!',
              //footer: '<a href>Why do I have this issue?</a>'
            })
          }
    }
    

    return (
        <div className='container'>
                <div className='card border-info mb-3'>
                    <div className='card-header resultados-header'>
                    <h4>Haz conseguido {(oportunidades ? oportunidades.length : null)} Oportunidades</h4>
                    <div className='flex-column'>
                        <span className='pr-2'>
                            <FontAwesomeIcon
                                className='c-azulEP'
                                icon={faCircle} size='xs'
                            /> Mes actual
                        </span>
                        <span className='pr-2'>
                            <FontAwesomeIcon
                                className='c-celesteEP'
                                icon={faCircle} size='xs'
                            /> Mes anterior
                        </span>
                        <span className='pr-2'>
                            <FontAwesomeIcon
                                className='c-grisEP'
                                icon={faCircle} size='xs'
                            /> Otros meses
                        </span>
                    </div>
                </div>
                <div className='card-body'>
                {(loadingSpinner === true) ? (<div className='spinner'><Spinner/></div>) : null}
                    <table className='table table-hover table-responsive'>
                        <thead>
                            <tr>
                            <th scope='col'>Fecha</th>
                            <th scope='col'>Empresa</th>
                            <th scope='col'>Nombre Contacto</th>
                            <th scope='col' className='text-center'>Periodo</th>
                            {/*<th scope='col'>Calificación</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                        {(oportunidades) ?
                            oportunidades.map((item, index) => {
                            return (
                                <tr className='table-light' key={index}>
                                    <td>{item.fecha_opo_entrega}</td>
                                    <th scope='col' onClick={() => handleOportunidadDetalle(item)}>{item.desc_prs_prospecto}</th>
                                    <td>{item.desc_prc_contacto_largo}</td>
                                    <td className='text-center'>
                                        {
                                            (item.f_opo_mesActual === 2 ) ?
                                            <FontAwesomeIcon
                                                className='c-azulEP'
                                                icon={faCircle} size='lg'
                                            /> : (item.f_opo_mesActual === 1 ) ?
                                            <FontAwesomeIcon
                                                className='c-celesteEP'
                                                icon={faCircle} size='lg'
                                            /> : (item.f_opo_mesActual === 0 ) ?
                                            <FontAwesomeIcon
                                                className='c-grisEP'
                                                icon={faCircle} size='lg'
                                            /> : null
                                        }
                                    </td>
                                    {/*<td>
                                        <RatingStar />
                                    </td>*/}
                                </tr>
                                );
                            })
                        : null}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Oportunidades;
