import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';
import tokenAuth from '../../config/token';
import AuthContext from './authContext';
import AuthReducer from './authReducer';

import 
{
    //REGISTRO_EXITOSO,
    //REGISTRO_ERROR,
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
    SPINNER_ON,
    SPINNER_OFF,
    CAMBIOPASS_EXITOSO,
    CAMBIOPASS_ERROR
    //,COMBO_PERIODO
}
from '../../types';

const AuthState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        autenticado: null,
        usuario: null,
        mensaje: null,
        loading: null,
        cargando: true,
        periodosCombo: [],
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    //Funciones

    //Iniciar Sesion
    const iniciarSesion = async datos  => {
        try {
            dispatch({
                type: SPINNER_ON
            });
            const respuesta = await clienteAxios.post('/api/auth', datos);    
            //console.log(respuesta.data);

            if (respuesta.data.recordset[0].id_status === 401){

                const alerta = {
                    msg: respuesta.data.recordset[0].msg,
                    categoria: 'alerta-error'
                }

                dispatch({
                    type: LOGIN_ERROR,
                    payload: alerta
                });
            
                dispatch({
                    type: SPINNER_OFF
                });    
                //console.log(alerta);

            }else {
                //console.log(respuesta.data.recordsets[0]);
                const payloadLogin = respuesta.data.recordsets[0]
                dispatch({
                    type: LOGIN_EXITOSO,
                    payload: payloadLogin[0]
                });
                //return;

                //console.log(respuesta.data.recordsets[1]);
                /*const payloadCombo = respuesta.data.recordsets[1]
                dispatch({
                    type: COMBO_PERIODO,
                    payload: payloadCombo
                });*/

                dispatch({
                    type: SPINNER_OFF
                });    
            }
        } catch (error) {
            const alerta = {
                msg: error.response,
                categoria: 'alerta-error'
            }

            dispatch({
                type: LOGIN_ERROR,
                payload: alerta
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
        
        
    }

    const usuarioAutenticado = async () => {
        const token = localStorage.getItem('token');
        if(token){
            //Envia el Token por Headers
            tokenAuth(token);
            //console.log('recargando');
        }
        try {
            const respuesta = await  clienteAxios.get('/api/auth/recargar');
            //console.log(respuesta.data.recordset.[0]);
            dispatch({
                type: OBTENER_USUARIO,
                payload: respuesta.data.recordset[0]
            });

        } catch (error) {
            //console.log(error);
            dispatch({
                type: LOGIN_ERROR
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const cerrarSesion = async () => {
        //console.log('click en cerrar sesion');
        dispatch({
            type: CERRAR_SESION
        })
    }

    const cambiarContraseña = async (datos) => {
        //console.log(datos);
        const token = localStorage.getItem('token');
        if(token){
            //Envia el Token por Headers
            tokenAuth(token);
            //console.log('CambiandoContraseña');
        }
        
        try {
            dispatch({
                type: SPINNER_ON
            }); 

            const respuesta = await  clienteAxios.post('/api/auth/cambiarContrasena', datos);
            //console.log(datos);

            if(respuesta.data.recordset[0].id_status === 401){
                //console.log('pasando por 401')
                const alerta = {
                    msg: respuesta.data.recordset[0].msg,
                    categoria: 'alerta-error'
                }

                dispatch({
                    type: CAMBIOPASS_ERROR,
                    payload: alerta
                });

            }else {
                const alerta = {
                    msg: respuesta.data.recordset[0].msg,
                    categoria: 'alerta-ok'
                }

                dispatch({
                    type: CAMBIOPASS_EXITOSO,
                    payload: alerta
                });
            }

            dispatch({
                type: SPINNER_OFF
            }); 

        } catch (error) {
            //console.log(error);
            dispatch({
                type: CAMBIOPASS_ERROR
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
        
    }

    return (
        <AuthContext.Provider
            value= {{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                mensaje: state.mensaje,
                loading: state.loading,
                cargando: state.cargando,
                periodosCombo: state.periodosCombo,
                iniciarSesion,
                usuarioAutenticado,
                cerrarSesion,
                cambiarContraseña
            }}
        >{props.children}

        </AuthContext.Provider>
    )
}

export default AuthState;