import React, {useContext} from 'react';

import FichaContext from '../../../../../context/ficha/fichaContext';
import AlertaContext from '../../../../../context/alerta/alertaContext';

const Pantalla5 = ({formData, setForm, navigation, preguntasFicha}) => {
    const {resp18, resp19, resp20} = formData

    const fichaContext = useContext(FichaContext);
    const { fnPantalla05Ficha } = fichaContext;

    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;
    

    const clickGuardarContinuar = (() => {
        //console.log(selTamanio);
        if (resp18 === '' || resp19 === '' || resp20 === ''){
            mostrarAlerta('¡Debe responder todas las preguntas!', 'alerta-error');
            return;
        }
        
        fnPantalla05Ficha(
            resp18.replace(/(\r\n|\n|\r)/g, '<br />'),
            resp19.replace(/(\r\n|\n|\r)/g, '<br />'),
            resp20.replace(/(\r\n|\n|\r)/g, '<br />')
        );
        navigation.next()
    });

    return (
        <div>
            { alerta? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null }
            <div className='form-group'>
                <h5>5.1- {preguntasFicha[17].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[17].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[17].desc_fip_preguntaT3}</h6>
                <textarea name='resp18' className='form-control' rows='2' defaultValue={resp18} onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>5.2- {preguntasFicha[18].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[18].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[18].desc_fip_preguntaT3}</h6>
                <textarea name='resp19' className='form-control' rows='1' defaultValue={resp19} onChange={setForm}></textarea>
            </div>

            <div className='form-group'>
                <h5>5.3- {preguntasFicha[19].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[19].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[19].desc_fip_preguntaT3}</h6>
                <textarea name='resp20' className='form-control' rows='1' defaultValue={resp20} onChange={setForm}></textarea>
            </div>

            <button
                className='btn btn-primario col-2 mt-4'
                onClick={() => clickGuardarContinuar()}
            >Siguiente
            </button>
            <button
                className='btn btn-secundario col-2 mt-4'
                onClick={() => navigation.previous()}
            >Volver
            </button>
                {/*<Button
                    variant='contained'
                    color='primary'
                    style={{marginTop:'1rem'}}
                    onClick={() => navigation.next()}
                >Siguiente
                </Button>*/}
        </div>
    )
}

export default Pantalla5;
