import React from 'react';

const PantallaTamanio = ({tamanioFicha, preguntasFicha, setSeleccion, seleccion}) => {

    const handleOnChange = (item, checked) => {
        //console.log(position, item, checked);
        //console.log(item);
        //console.log(checked);

        if(checked){
            //console.log('verdadero');
            const nuevo = {id: item.id_agrupacion, valor: checked, f_valor: (checked === true) ? 1 : 0}
            setSeleccion([...seleccion, nuevo]);
        }else{
            //console.log('falso');
            const seleccion2 = seleccion.filter(x => (x.id !== item.id_agrupacion && x.valor === true));
            //console.log(seleccion2);
            setSeleccion(seleccion2);
        }
    };
      
      
    //console.log(seleccion);
    return (
        <fieldset>
            <div className='form-group'>
                <h5>2.1- {preguntasFicha[1].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[1].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[1].desc_fip_preguntaT3}</h6>
                <fieldset>
                    {tamanioFicha.map((item, index) => {
                        return (
                            <div className='form-check form-switch' key={item.id_agrupacion} >
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id={item.id_agrupacion}
                                    //onClick={() => handleOnChange(item.id_agrupacion)}
                                    //checked={checkedState[index]}
                                    onChange={(e) => handleOnChange(item, e.target.checked)}
                                    //defaultValue={defaultValue}
                                />
                                <label className='form-check-label' htmlFor={item.id_agrupacion}>{item.desc_agrupacion}</label>
                            </div>
                        )
                    })}
                </fieldset>
                {/*<textarea name='resp1' className='form-control' rows='1' defaultValue={resp1} onChange={setForm}></textarea>*/}
            </div>
        </fieldset>

    )
}

export default PantallaTamanio;
