import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faHammer
    , faPaintRoller
  } from '@fortawesome/free-solid-svg-icons';

const Parametros = () => {
    return (
        <div className='container'>
            <div className='card border-info mb-3'>
                <div className='card-header'>
                    <div className='tableTitle'>
                        <h4 className='table-title'>{'Parámetros de Prospección'}</h4>
                        {/*(nuevoMsg) ? 
                            <button
                                className='btnCorto btn-cancelar'
                                data-toggle='tooltip' data-placement='top' title='Nuevo'
                                onClick={() => OnClickNuevoMsg()}
                            >
                            <FontAwesomeIcon
                                    className='btn-table-title-icon'
                                    icon={faWindowClose} size='lg'
                                />Cancelar
                        </button> : 
                            <button
                                className='btnCorto btn-primario'
                                data-toggle='tooltip' data-placement='top' title='Nuevo'
                                onClick={() => OnClickNuevoMsg()}
                                //disabled={btnDisableNuevoMsg}
                            >
                                <FontAwesomeIcon
                                    className='btn-table-title-icon'
                                    icon={faPlusCircle} size='lg'
                                />Nuevo
                            </button>
                        */}
                    </div>
                </div>
                <div className='card-body'>
                    <h5 className='text-center'>
                        <FontAwesomeIcon icon={faHammer} size='lg'/> <FontAwesomeIcon icon={faPaintRoller} size='lg'/>
                        <p className='parrafo text-center'>Disponible Próximamente.</p>
                        <p className='parrafo text-center'>Pronto podrás cambiar tu foco de prospección desde este lugar.</p>
                        <p className='parrafo text-center'>Si necesitas realizar un cambio ahora, escribenos a <span>sac@elprospector.cl</span>.</p>
                    </h5>
                    {/*(nuevoMsg) ? <MensajesNuevo /> : 

                    <table className='table table-hover table-responsive'>
                        <thead>
                            <tr>
                            <th scope='col'>Fecha Creación</th>
                            <th scope='col'>Mensaje</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(mensajesUsados) ?
                            mensajesUsados.map((item, index) => {
                                return (
                                    <tr className='table-light' key={index}>
                                        {//<th scope='row'>{item.f_cis_email_subject}</th>
                                        }
                                        <td>
                                            {item.fecha_msg_creacion}
                                            {(item.id_msg_estado === 'MSG-002') ?
                                                <span className={`badge badge-pill badge-warning`}>{item.desc_msg_estado}</span>
                                            : null}
                                            
                                        </td>
                                        <td dangerouslySetInnerHTML={{__html: item.desc_msg_mensaje}}></td>
                                    </tr>
                                );
                        })
                        : null}
                        </tbody>
                    </table>
                   */}
                </div>
            </div>
        </div>
    )
}

export default Parametros;