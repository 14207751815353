import React, {useContext, useEffect, useState} from 'react';
import MensajesNuevo from './MensajesNuevo';
//import Modal from '../../comunes/Modal';
import ServicioContext from '../../../../context/servicio/servicioContext';
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faPlusCircle
    , faWindowClose
    , faCircle
  } from '@fortawesome/free-solid-svg-icons';

const Mensajes = () => {
    //Context
    const servicioContext = useContext(ServicioContext);
    const { mensajesUsados, fnHistorialMensajes } = servicioContext;

    //State
    const [nuevoMsg, setNuevoMsg] = useState(false);
    const [titulo, setTitulo] = useState('Historial de Mensajes');
    const [btnDisableNuevoMsg, setBtnDisableNuevoMsg] = useState(false);
    /*
    const modalTitle = 'Información Nuevo Mensaje' 
    const modalBody = `Mensaje es revisado por equipo El Prospector, y será utilizado en máximo 5 días hábiles
    *Si hay observaciones parar mejorar la solicitud, serás contactado y hay 24 hrs para aceptar los cambios. Si no hay respuesta saldrémos a prospectar con tu solicitud.
    *Opción de utilizar nombre de la empresa, nombre del contacto y link de landing a prospectar es opcional (Recomendado utilizarlo).
    *2000 carácteres disponibles (incluye variables)
    *Siempre se utiliza 1 solo mensaje de prospección, el mas cargado más recientemente.
    *Si deseas volver a utilizar un mensaje anterior, ponte en contacto con sac@elprospector.cl`
    */
    useEffect( () => {
        if(mensajesUsados) {
            //console.log(mensajesUsados[0].id_msg_estado);
            if(mensajesUsados[0].id_msg_estado === 'MSG-002'){
                setBtnDisableNuevoMsg(true);
            }else{
                setBtnDisableNuevoMsg(false);
            }
            return
        };
        
        fnHistorialMensajes();

        // eslint-disable-next-line
    }, [mensajesUsados]);


    const OnClickNuevoMsg = () =>{
        if(btnDisableNuevoMsg){
            Swal.fire({
                icon: 'error',
                title: 'Ya existe un mensaje en revisión!',
                text: 'Si necesitas cargar otro, contacta a sac@elprospector.cl para cargar uno nuevo.',
            })
        }else{
            if(!nuevoMsg){
                setTitulo('Ingresa tu nuevo mensaje');
            }else{
                setTitulo('Historial de Mensajes');
            }
            setNuevoMsg(!nuevoMsg);
        }
    }

    //const idMsgEstadoPendiente = mensajesUsados[0].id_msg_estado || 'MSG-001';
        
    

    return (
        <div className='container'>
            <div className='card border-info mb-3'>
                <div className='card-header resultados-header'>
                        <h4>{titulo}</h4>
                        <div className='flex-column'>
                            <span className='pr-2'>
                                <FontAwesomeIcon
                                    className='c-azulEP'
                                    icon={faCircle} size='xs'
                                /> Activo
                            </span>
                            <span className='pr-2'>
                                <FontAwesomeIcon
                                    className='c-celesteEP'
                                    icon={faCircle} size='xs'
                                /> Próximo a Usar
                            </span>
                            <span className='pr-2'>
                                <FontAwesomeIcon
                                    className='c-naranjoEP'
                                    icon={faCircle} size='xs'
                                /> En Revisión
                            </span>
                            <span className='pr-2'>
                                <FontAwesomeIcon
                                    className='c-grisEP'
                                    icon={faCircle} size='xs'
                                /> No Activo
                            </span>
                        </div>
                        {(nuevoMsg) ? 
                            <button
                                className='btnCorto btn-cancelar'
                                data-toggle='tooltip' data-placement='top' title='Nuevo'
                                onClick={() => OnClickNuevoMsg()}
                            >
                            <FontAwesomeIcon
                                    className='btn-table-title-icon'
                                    icon={faWindowClose} size='lg'
                                />Cancelar
                        </button> :
                        <button
                            className='btnCorto btn-primario'
                            data-toggle='tooltip' data-placement='top' title='Nuevo'
                            onClick={() => OnClickNuevoMsg()}
                        //disabled={btnDisableNuevoMsg}
                        >
                            <FontAwesomeIcon
                                className='btn-table-title-icon'
                                icon={faPlusCircle} size='lg'
                            />Nuevo
                        </button>
                        }
                </div>
                <div className='card-body'>
                    {(nuevoMsg) ? <MensajesNuevo /> :
                    <div>
                        <select className='form-select form-select-lg btn-naranjo rounded ml-2' aria-label='.form-select-lg example'
                            //onClick={(e) => selectTipoMensaje(e)}
                        >
                            <option value={0} defaultValue>Estado de Mensajes</option>
                            {/*IdTipoMensaje.map((item, index) => (
                                    <option key={index} value={item.idTipoMensaje}>{item.descTipoMensaje}</option>
                            ))*/}
                        </select>


                        <table className='table table-hover table-responsive'>
                            <thead>
                                <tr>
                                    <th scope='col'>Fecha Creación</th>
                                    <th scope='col'>Tipo de Envio</th>
                                    <th scope='col'>Mensaje</th>
                                    <th scope='col'>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(mensajesUsados) ?
                                mensajesUsados.map((item, index) => {
                                    return (
                                        <tr className='table-light' key={index}>
                                            {/*<th scope='row'>{item.f_cis_email_subject}</th>*/}
                                            <td>
                                                {item.fecha_msg_creacion}
                                                
                                            </td>
                                            <td>
                                                <span className={`badge badge-pill badge-secondary`}>{item.desc_msg_tipo_envio}</span>
                                            </td>
                                            <td dangerouslySetInnerHTML={{__html: item.desc_msg_mensaje}}></td>
                                            <td className='text-center'>
                                            {
                                                (item.f_msg_estado === 1 ) ?
                                                <FontAwesomeIcon
                                                    className='c-azulEP'
                                                    icon={faCircle} size='lg'
                                                /> : (item.f_msg_estado === 2 ) ?
                                                <FontAwesomeIcon
                                                    className='c-naranjoEP'
                                                    icon={faCircle} size='lg'
                                                /> : (item.f_msg_estado === 3 ) ?
                                                <FontAwesomeIcon
                                                    className='c-grisEP'
                                                    icon={faCircle} size='lg'
                                                /> : (item.f_msg_estado === 5 ) ?
                                                <FontAwesomeIcon
                                                    className='c-celesteEP'
                                                    icon={faCircle} size='lg'
                                                /> : null
                                            }
                                            </td>
                                            
                                            
                                        </tr>
                                    );
                            })
                            : null}
                            </tbody>
                        </table>
                    </div>
                    
                    }
                </div>
            </div>
        </div>
    )
}

export default Mensajes;