import 
{
    //REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
    SPINNER_ON,
    SPINNER_OFF,
    CAMBIOPASS_EXITOSO,
    CAMBIOPASS_ERROR,
    COMBO_PERIODO
}
from '../../types';

export default (state, action) => {
    switch(action.type){
        case SPINNER_ON:
            return{
                ...state,
                //panel:'cargando',
                loading: true
            }
        case SPINNER_OFF:
            return{
                ...state,
                loading: false
            }
        case OBTENER_USUARIO:
            return{
                ...state,
                autenticado: true,
                usuario: action.payload,
                mensaje: null,
                cargando: false
            }
        case LOGIN_EXITOSO:
            localStorage.setItem('token', action.payload.tkn);
            return{
                ...state,
                autenticado: true,
                usuario: action.payload,
                mensaje: null,
                cargando: false
            }
        case CAMBIOPASS_EXITOSO:
            return{
                ...state,
                mensaje: action.payload
            }
        case CAMBIOPASS_ERROR:
            return{
                ...state,
                mensaje: action.payload
            }
        case COMBO_PERIODO:
            return{
                ...state,
                comboPeriodo: action.payload
            }
        case CERRAR_SESION:
        case REGISTRO_ERROR:
        case LOGIN_ERROR:
            localStorage.removeItem('token');
            return{
                ...state,
                token: null,
                autenticado: null,
                usuario: null,
                mensaje: action.payload,
                loading: null
            }
        
        default:
            return state;
    }
}