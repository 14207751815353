import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointer } from '@fortawesome/free-solid-svg-icons';
import ServicioContext from '../../../../context/servicio/servicioContext';


const ReporteMensualTablaClientes = ({setClienteSeleccion, clienteSeleccion}) => {
    const servicioContext = useContext(ServicioContext);
    const { rmClientes } = servicioContext;

    

    
    const clienteSeleccionado = (e) => {
        //console.log(e.id_cli_rut);
        setClienteSeleccion(e.id_cli_rut);
    }

  return (
    <>
    <table className='table table-hover table-responsive table-sm thead6m'>
        <thead>
            <tr>
                <th scope='col'><FontAwesomeIcon icon={faHandPointer} className='c-azulEP' /></th>
                <th scope='col'>Cliente</th>
                <th scope='col'>Fecha Inicio</th>
                <th scope='col'>Fecha Fin</th>
            </tr>
        </thead>
        <tbody>
            {(rmClientes) ?
                rmClientes.map((item) => {
                return (
                    <tr className='table-info' key={item.id_cli_rut}>
                        <td className='text-center'>
                            <div className='input-group'>
                                <div className='input-group-prepend'>
                                    <div className='input-group-text'>
                                        <input
                                            type='radio'
                                            name={`optionRadioCliente6M${item.id_cli_rut}`} id={`optionRadioCliente6M${item.id_cli_rut}`}
                                            value={item.id_cli_rut} checked={(item.id_cli_rut === clienteSeleccion) ? true : false}
                                            onChange={()=>(clienteSeleccionado(item))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className='text-center'>{item.desc_cli_cliente}</td>
                        <td className='text-center'>{item.fecha_cli_ing}</td>
                        <td className='text-center'>{item.fecha_cli_fin}</td>
                    </tr>
                    );
                })
            : null}
        </tbody>
    </table>
    </>
  )
}

export default ReporteMensualTablaClientes;