import React, { Fragment } from 'react';
import { ResultadosNavTabs, ResultadosTabContent } from './ResultadosData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Resultados = () => {;
    return (
        <Fragment>
          <div id= 'navTab' className='container page'>
            <ul className='nav nav-tabs nav-justified flex-columns'>
              {ResultadosNavTabs.map((item, index) => {
                return (
                  <li key={index} className={item.cNameLi}>
                    <a href={item.path} className={item.cNameA} data-toggle={item.dataToggle}>
                      <FontAwesomeIcon icon={item.icon} /> <span className=''>{item.title}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
            <div id='contentTab' className='tab-content'>
              {ResultadosTabContent.map((item, index) => {
                return(
                  <div key={index} className={item.cName} id={item.id}>
                    {item.component}
                  </div>
                )
              })}
            </div>
          </div>
        </Fragment>
    )
}

export default Resultados;
