import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';
import tokenAuth from '../../config/token';
import ServicioContext from './servicioContext';
import ServicioReducer from './servicioReducer';

import 
{
    SPINNER_ON,
    SPINNER_OFF,
    SERVICIO_ERROR,
    CERRAR_SESION,
    ASUNTO_ULTIMO,
    MENSAJE_ULTIMO,
    ASUNTOS_USADOS,
    REPORTE_OBTENER,
    REPORTETOTAL_OBTENER,
    FECHASERVICIO_OBTENER,
    PERFILSERVICIO_OBTENER,
    //DETALLEAPERTURA_OBTENER,
    MENSAJES_USADOS,
    MENSAJECREAR_EXITOSO,
    ASUNTOCREAR_EXITOSO,
    FECHAS_ACTUALIZACION,
    RMCLIENTES_OBTENER,
    RMDATOS6M_OBTENER,
    RM6M_OBTENER,
    RMDETALLEEMPRESA6M,
    RMDETALLECONTACTO6M,
    COMBO_PERIODO
}
from '../../types';

const ServicioState = props => {
    const initialState = {
        panel: null,
        ultAsunto: [],
        ultMensaje: [],
        asuntosUsados: null,
        reporteMensual: [],
        reporteMensualTotal: [],
        rmClientes: [],
        rm6M: [],
        rmDatos6M: [],
        rmDetalleEmpresa6M: [],
        rmDetalleContacto6M: [],
        FechaServicio: [],
        fechasActualizacion: [],
        perfilServicio: null,
        //detalleApertura: [],
        mensajesUsados: null,
        mensaje: null,
        loadingSpinner: false
    }
    
    const [state, dispatch] = useReducer(ServicioReducer, initialState);


    const fnUltimoAsunto = async () => {
        const token = localStorage.getItem('token');
        if(token){
            //Envia el Token por Headers
            tokenAuth(token);
            //console.log('recargando');
        }

        try {

            dispatch({
                type: SPINNER_ON
            }); 

            const respuesta = await  clienteAxios.post('/api/servicio/ultimoAsunto');
            //console.log(respuesta.data.recordset);

            dispatch({
                type: ASUNTO_ULTIMO,
                payload: respuesta.data.recordset
            });

            dispatch({
                type: SPINNER_OFF
            }); 

        } catch (error) {
            console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnUltimoMensaje = async () => {
        const token = localStorage.getItem('token');
        if(token){
            //Envia el Token por Headers
            tokenAuth(token);
            //console.log('recargando');
        }

        try {

            dispatch({
                type: SPINNER_ON
            }); 

            const respuesta = await  clienteAxios.post('/api/servicio/ultimoMensaje');
            //console.log(respuesta.data.recordsets);

            dispatch({
                type: MENSAJE_ULTIMO,
                payload: respuesta.data.recordset
            });

            dispatch({
                type: SPINNER_OFF
            }); 

        } catch (error) {
            console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnHistorialAsuntos = async () => {
        try {

            dispatch({
                type: SPINNER_ON
            }); 

            const respuesta = await  clienteAxios.post('/api/servicio/historialAsuntos');
            //console.log(respuesta.data.recordset);

            dispatch({
                type: ASUNTOS_USADOS,
                payload: respuesta.data.recordset
            });

            dispatch({
                type: SPINNER_OFF
            }); 

        } catch (error) {
            console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnObtieneAsuntos = async () => {
        try {

            dispatch({
                type: SPINNER_ON
            }); 

            //Desuso fnUltimoAsunto Jun 2023, ya no se usan datos desde IS y Mailchimp
            //await fnUltimoAsunto();
            await fnHistorialAsuntos();
            await fnUltimoMensaje();

        } catch (error) {
            console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnReporteMensual = async () => {
        //Desuso Oct-Nov 2022
        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await  clienteAxios.post('/api/servicio/reporteMensual');
            //console.log(respuesta.data.recordsets[0]);

            dispatch({
                type: FECHASERVICIO_OBTENER,
                payload: respuesta.data.recordsets[0]
            });

            dispatch({
                type: REPORTE_OBTENER,
                payload: respuesta.data.recordsets[1]
            });

            dispatch({
                type: REPORTETOTAL_OBTENER,
                payload: respuesta.data.recordsets[2][0]
            });

            dispatch({
                type: SPINNER_OFF
            });
            
            //fnFechasActualizacion();
        } catch (error) {
            console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnReporteMensual6M30D = async () => {
        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await  clienteAxios.post('/api/servicio/reporteMensualMovil6Meses');
            //console.log(respuesta.data.recordsets[0]);
                        
            dispatch({
                type: RMCLIENTES_OBTENER,
                payload: respuesta.data.recordsets[0] //rmClientes
            });

            dispatch({
                type: RM6M_OBTENER,
                payload: respuesta.data.recordsets[1] //rm6M
            });
            
            dispatch({
                type: RMDATOS6M_OBTENER,
                payload: respuesta.data.recordsets[2] //rmDatos6M
            });

            dispatch({
                type: RMDETALLEEMPRESA6M,
                payload: respuesta.data.recordsets[3] //rmDetalle6M
            });

            dispatch({
                type: RMDETALLECONTACTO6M,
                payload: respuesta.data.recordsets[4] //rmDatos30D
            });

            dispatch({
                type: SPINNER_OFF
            });
            
            //fnFechasActualizacion();
        } catch (error) {
            console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnPerfilServicio = async () => {
        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await  clienteAxios.post('/api/servicio/perfilServicio');
            //console.log(respuesta.data.recordset[0]);

            dispatch({
                type: PERFILSERVICIO_OBTENER,
                payload: respuesta.data.recordset[0]
            });

            dispatch({
                type: SPINNER_OFF
            });
            
        } catch (error) {
            console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnHistorialMensajes = async () => {
        const token = localStorage.getItem('token');
        if(token){
            //Envia el Token por Headers
            tokenAuth(token);
            //console.log('recargando');
        }

        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await  clienteAxios.post('/api/servicio/historialMensajes');
            //console.log(JSON.parse(respuesta.data.recordset[0].data_msg));

            dispatch({
                type: MENSAJES_USADOS,
                payload: JSON.parse(respuesta.data.recordset[0].data_msg)
            });

            dispatch({
                type: SPINNER_OFF
            });
            
        } catch (error) {
            console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnMensajeCrear = async (mensaje, link, tipoEnvio) => {
        const datos = ({
            desc_msg_mensaje: mensaje.desc_msg_mensaje,
            desc_msg_link: link.desc_msg_link,
            id_msg_tipo_envio: tipoEnvio
        })
        //console.log(datos);

        const token = localStorage.getItem('token');
        if(token){
            //Envia el Token por Headers
            tokenAuth(token);
            //console.log('CambiandoContraseña');
        }
        
        try {
            dispatch({
                type: SPINNER_ON
            }); 

            const respuesta = await  clienteAxios.post('/api/servicio/mensajeCrear', datos);
            //console.log(respuesta.data.recordset[0]);

            if(respuesta.data.recordset[0].id_status === 401){
                //console.log('pasando por 401')
                const alerta = {
                    msg: respuesta.data.recordset[0].msg,
                    categoria: 'alerta-error'
                }

                dispatch({
                    type: SERVICIO_ERROR,
                    payload: alerta
                });

            }else {
                //console.log(respuesta.data.recordset[0].msg);
                const alerta = {
                    msg: respuesta.data.recordset[0].msg,
                    categoria: 'alerta-ok'
                }

                dispatch({
                    type: MENSAJECREAR_EXITOSO,
                    payload: alerta
                });
                
                fnHistorialMensajes();
                
            }

            dispatch({
                type: SPINNER_OFF
            }); 

        } catch (error) {
            //console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
        
    }

    const fnAsuntoCrear = async (datos) => {
        //console.log(datos);
        const token = localStorage.getItem('token');
        if(token){
            //Envia el Token por Headers
            tokenAuth(token);
            //console.log('CambiandoContraseña');
        }
        
        try {
            dispatch({
                type: SPINNER_ON
            }); 

            const respuesta = await  clienteAxios.post('/api/servicio/asuntoCrear', datos);
            //console.log(respuesta.data.recordset[0]);

            if(respuesta.data.recordset[0].id_status === 401){
                //console.log('pasando por 401')
                const alerta = {
                    msg: respuesta.data.recordset[0].msg,
                    categoria: 'alerta-error'
                }

                dispatch({
                    type: SERVICIO_ERROR,
                    payload: alerta
                });

            }else {
                //console.log(respuesta.data.recordset[0].msg);
                const alerta = {
                    msg: respuesta.data.recordset[0].msg,
                    categoria: 'alerta-ok'
                }

                dispatch({
                    type: ASUNTOCREAR_EXITOSO,
                    payload: alerta
                }); 
                
                fnObtieneAsuntos();
            }

            dispatch({
                type: SPINNER_OFF
            }); 

        } catch (error) {
            //console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            })

            dispatch({
                type: SPINNER_OFF
            }); 
        }
        
    }

    const fnFechasActualizacion = async () => {
        //console.log('fechas actualizacion');
        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await clienteAxios.post('/api/servicio/fechasActualizacion');
            console.log(respuesta.data.recordset[0]);
            
            dispatch({
                type: FECHAS_ACTUALIZACION,
                payload: respuesta.data.recordset
            });

            dispatch({
                type: SPINNER_OFF
            });
            
        } catch (error) {
            console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }

    const fnComboPeriodo = async () => {
        //console.log('fnComboPeriodo');
        try {

            dispatch({
                type: SPINNER_ON
            });

            const respuesta = await clienteAxios.post('/api/servicio/comboPeriodo');
            //console.log(respuesta.data.recordset);
            
            dispatch({
                type: COMBO_PERIODO,
                payload: respuesta.data.recordset
            });

            dispatch({
                type: SPINNER_OFF
            });
            
        } catch (error) {
            console.log(error);
            dispatch({
                type: SERVICIO_ERROR
            });

            dispatch({
                type: SPINNER_OFF
            }); 
        }
    }


    const fnServicioCerrarSesion = async () => {
        //console.log('click en cerrar sesion');
        dispatch({
            type: CERRAR_SESION
        })
    }

    return (
        <ServicioContext.Provider
            value= {{
                panel: state.panel,
                comboPeriodo: state.comboPeriodo,
                ultAsunto: state.ultAsunto,
                ultMensaje: state.ultMensaje,
                asuntosUsados: state.asuntosUsados,
                loadingSpinner: state.loadingSpinner,
                reporteMensual: state.reporteMensual,
                reporteMensualTotal: state.reporteMensualTotal,
                rmClientes: state.rmClientes,
                rm6M: state.rm6M,
                rmDatos6M: state.rmDatos6M,
                rmDetalleEmpresa6M: state.rmDetalleEmpresa6M,
                rmDetalleContacto6M: state.rmDetalleContacto6M,
                FechaServicio: state.FechaServicio,
                perfilServicio: state.perfilServicio,
                mensajesUsados: state.mensajesUsados,
                mensaje: state.mensaje,
                fechasActualizacion: state.fechasActualizacion,
                fnUltimoAsunto: fnUltimoAsunto,
                fnUltimoMensaje: fnUltimoMensaje,
                fnHistorialAsuntos: fnHistorialAsuntos,
                fnObtieneAsuntos: fnObtieneAsuntos,
                fnServicioCerrarSesion: fnServicioCerrarSesion,
                fnReporteMensual: fnReporteMensual,
                fnPerfilServicio: fnPerfilServicio,
                fnHistorialMensajes: fnHistorialMensajes,
                fnMensajeCrear: fnMensajeCrear,
                fnAsuntoCrear: fnAsuntoCrear,
                fnFechasActualizacion: fnFechasActualizacion,
                fnComboPeriodo: fnComboPeriodo,
                fnReporteMensual6M30D: fnReporteMensual6M30D
            }}
        >
            {props.children}
        </ServicioContext.Provider>
    )
}

export default ServicioState;