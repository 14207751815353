import 
{
    SPINNER_ON,
    SPINNER_OFF,
    CERRAR_SESION,
    RESULTADOS_ERROR,
    OPORTUNIDADES_OBTENER,
    SINFORMACION_OBTENER,
    DETALLEAPERTURA_OBTENER,
    LANDING_OBTENER
}
from '../../types';

export default (state, action) => {
    switch(action.type){
        case SPINNER_ON:
            return{
                ...state,
                loadingSpinner: true
            }
        case SPINNER_OFF:
            return{
                ...state,
                loadingSpinner: false
            }
        case DETALLEAPERTURA_OBTENER:
            return{
                ...state,
                detalleAperturas: action.payload
            }
        case OPORTUNIDADES_OBTENER:
            return{
                ...state,
                oportunidades: action.payload
            }
        case SINFORMACION_OBTENER:
            return{
                ...state,
                sinformacion: action.payload
            }
        case LANDING_OBTENER:
            return{
                ...state,
                detalleLanding: action.payload
            }
        case RESULTADOS_ERROR:
        case CERRAR_SESION:
            return{
                ...state,
                oportunidades: null,
                sinformacion: null,
                detalleAperturas: null,
                loadingSpinner: null
            }
        default:
            return state;
    }
}