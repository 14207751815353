import React, { Fragment, useState, useContext, useEffect } from 'react';
import Spinner from '../../layout/spinner/Spinner';
import AlertaContext from '../../../context/alerta/alertaContext';
import AuthContext from '../../../context/autentication/authContext';

import { 
    TextField,
    InputAdornment
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen, faLock, faSave } from '@fortawesome/free-solid-svg-icons';

const CambioContraseña = () => {
    //Context Alerta
    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;

    //Context Auth
    const authContext = useContext(AuthContext);
    const { mensaje, loading, cambiarContraseña } = authContext;
    
    useEffect(() => {
        if(mensaje) {
            mostrarAlerta(mensaje.msg, mensaje.categoria);
        }
        //eslint-disable-next-line
    }, [mensaje]);

    //const btnDisabled = false;

    
    //State local para guardar y confirmar contraseñas ingresadas
    const [password, setPassword ] = useState({
        desc_usu_password:'',
        desc_usu_password_nueva: '',
        desc_usu_password_confirmada:''
    });

    //extraccion
    const {desc_usu_password, desc_usu_password_nueva, desc_usu_password_confirmada} = password;

    const OnChangePassword = e =>{
        setPassword({
            ...password, 
            [e.target.name] : e.target.value
        });

        //console.log(password);
    }

    const onSubmitPassword = e => {
        e.preventDefault();
        //console.log(e);
        //Validar
        if (desc_usu_password.trim() === '' || desc_usu_password_nueva.trim() === '' || desc_usu_password_confirmada.trim() === ''){
            mostrarAlerta('¡Todos los campos son obligatorios!', 'alerta-error');
            return;
        }

        if (desc_usu_password_nueva.trim() !== desc_usu_password_confirmada.trim() ){
            mostrarAlerta('¡Contraseñas Nuevas No Coinciden!', 'alerta-error');
            return;
        }

        //Enviamos datos para iniciar sesion
        //console.log(desc_usu_email, desc_usu_password);
        cambiarContraseña({ desc_usu_password, desc_usu_password_nueva });
    }

    return (
        <Fragment>
        <div className='card border-info mb-3'>
            <div className='card-header'><h4>Cambiar Contraseña</h4></div>
            <div className='card-body'>
                <form>
                    <div className='container row'>
                        <blockquote className='blockquote'>
                            <footer className='blockquote-footer'>Contraseñas NO PUEDEN comenzar ni terminar con Espacios
                                <cite className='Source Title'>(serán eliminados)</cite>
                            </footer>
                            <footer className='blockquote-footer'>Debe tener minimo 6 Caracteres
                            </footer>
                        </blockquote>
                        
                        { alerta? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null }
                        <TextField
                            className='col-sm-12 md-6'
                            name='desc_usu_password'
                            label='Contraseña Actual'
                            variant='filled'
                            type='text'
                            onChange={OnChangePassword}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                    <FontAwesomeIcon className='icoLogin' icon={faLockOpen} />
                                    </InputAdornment>
                                ),
                            }}
                        /> 

                        <TextField
                            className='col-sm-12 md-6 mt-4'
                            name='desc_usu_password_nueva'
                            label='Nueva Contraseña'
                            variant='filled'
                            type='password'
                            onChange={OnChangePassword}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                    <FontAwesomeIcon className='icoLogin' icon={faLock} />
                                    </InputAdornment>
                                ),
                            }}
                        />  

                        <TextField
                            className='textField col-sm-12 md-6 mt-4'
                            name='desc_usu_password_confirmada'
                            label='Confirmar Nueva Contraseña'
                            variant='filled'
                            type='password'
                            onChange={OnChangePassword}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                    <FontAwesomeIcon className='icoLogin' icon={faLock} />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {(loading === true) ? (<Spinner/>) : null}
                        <button className='btn btn-primario col-sm-12 mt-4' onClick={onSubmitPassword}>
                            <FontAwesomeIcon icon={faSave} size='2x'/>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        
        </Fragment>
    )
}

export default CambioContraseña;
