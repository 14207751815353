import 
{
    SPINNER_ON,
    SPINNER_OFF,
    //EMAIL_ITEMAPROBADO,
    //EMAIL_ERROR
}
from '../../types';

export default (state, action) => {
    switch(action.type){
        case SPINNER_ON:
            return{
                ...state,
                loadingSpinner: true
            }
        case SPINNER_OFF:
            return{
                ...state,
                loadingSpinner: false
            }
        default:
            return state;
    }
}