import React, {useContext, useState} from 'react';
import PantallaTamanio from './PantallaTamanio';
import PantallaAreaPadre from './PantallaAreaPadre';
import PantallaAreaHijo from './PantallaAreaHijo';
import PantallaSector from './PantallaSector';

import FichaContext from '../../../../../context/ficha/fichaContext';
import AlertaContext from '../../../../../context/alerta/alertaContext';

const Pantalla2 = ({formData, setForm, navigation, preguntasFicha}) => {
    //console.log('pantalla2');
    const {/*resp1, resp2, resp3, resp4,*/ resp5} = formData

    const fichaContext = useContext(FichaContext);
    const { tamanioFicha, areaPadreFicha, areaHijoFicha, sectorFicha, fnSeleccionAreaHijo, fnPantalla02Ficha } = fichaContext;

    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;

    //console.log(areaHijoFicha);

    const [selTamanio, setSelTamanio] = useState([]);
    const [selAreaPadre, setSelAreaPadre] = useState([]);
    const [opcAreaHijo, setOpcAreaHijo] = useState([]);
    const [selAreaHijo, setSelAreaHijo] = useState([]);
    const [selSector, setSelSector] = useState([]);
    const [disableBtnGuardar, setDisableBtnGuardar] = useState(false);
    const [nAreaPadre, setNAreaPadre] = useState({
        limite: 2,
        contador: 3
    });

    const clickGuardarContinuar = (() => {
        //console.log(selTamanio);
        if (resp5 === '' || selTamanio.length === 0 || selAreaPadre.length === 0 || opcAreaHijo === 0 || selAreaHijo === 0 || selSector === 0){
            mostrarAlerta('¡Debe responder todas las preguntas!', 'alerta-error');
            return;
        }
        
        fnPantalla02Ficha(selTamanio, selAreaPadre, selAreaHijo, selSector, resp5.replace(/(\r\n|\n|\r)/g, '<br />'));
        navigation.next()
    });
    

    return (
        <div>
            { alerta? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null }
            <PantallaTamanio
                tamanioFicha = {tamanioFicha}
                preguntasFicha = {preguntasFicha}
                setSeleccion = {setSelTamanio}
                seleccion = {selTamanio}
                setDisableBtnGuardar={setDisableBtnGuardar}
                //setForm = {setForm}
                //defaultValue={resp1}
            />
            {/*<textarea name='resp1' className='form-control' rows='1' defaultValue={resp1} onChange={setForm}></textarea>*/}

            <PantallaAreaPadre
                areaPadreFicha = {areaPadreFicha}
                areaHijoFicha = {areaHijoFicha}
                preguntasFicha = {preguntasFicha}
                setSeleccion = {setSelAreaPadre}
                seleccion = {selAreaPadre}
                setDisableBtnGuardar = {setDisableBtnGuardar}
                disableBtnGuardar={disableBtnGuardar}
                setNAreaPadre = {setNAreaPadre}
                nAreaPadre = {nAreaPadre}
                setOpcAreaHijo = {setOpcAreaHijo}
                opcAreaHijo = {opcAreaHijo}
                fnSeleccionAreaHijo = {fnSeleccionAreaHijo}
                //setForm = {setForm}
                //defaultValue={resp1}
            />

            <PantallaAreaHijo
                preguntasFicha = {preguntasFicha}
                opcAreaHijo = {opcAreaHijo}
                selAreaHijo = {selAreaHijo}
                setSelAreaHijo = {setSelAreaHijo}
            />

            
            <PantallaSector
                preguntasFicha = {preguntasFicha}
                sectorFicha = {sectorFicha}
                selSector = {selSector}
                setSelSector = {setSelSector}
            />
           
            <div className='form-group'>
                <h5>2.5- {preguntasFicha[5].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[5].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[5].desc_fip_preguntaT3}</h6>
                <textarea name='resp5' className='form-control' rows='3' defaultValue={resp5} onChange={setForm}></textarea>
            </div>

            <button
                className='btn btn-primario col-2 mt-4'
                onClick={() => clickGuardarContinuar()}
                disabled={disableBtnGuardar}
            >Siguiente
            </button>

            <button
                className='btn btn-secundario col-2 mt-4'
                onClick={() => navigation.previous()}
            >Volver
            </button>
                {/*<Button
                    variant='contained'
                    color='primary'
                    style={{marginTop:'1rem'}}
                    onClick={() => navigation.next()}
                >Siguiente
                </Button>*/}
        </div>
    )
}

export default Pantalla2;
