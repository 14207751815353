import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointer } from '@fortawesome/free-solid-svg-icons';
import ServicioContext from '../../../../context/servicio/servicioContext';


const ReporteMensualTablaClientes6M = ({tipoGestionSeleccion, rmDatos6MSeleccion, setTipoGestionSeleccion}) => {
    const servicioContext = useContext(ServicioContext);
    const { rm6M } = servicioContext;



    const tipoGestionSeleccionado = (e) => {
        //console.log(e.id_opo_tipo_ingreso);
        setTipoGestionSeleccion(e.id_opo_tipo_ingreso);
    }


    return (
    <>
    <table className='table table-hover table-responsive table-sm thead6m'>
        <thead>
            <tr>
                <th scope='col'><FontAwesomeIcon icon={faHandPointer} className='c-azulEP' /></th>
                <th scope='col'>Item</th>
                {(rm6M) ?
                    rm6M.map((item, index) => {
                    return (
                            <th scope='col' key={index}>{item.desc_tie_mes_corto}</th>
                        );
                    })
                : null}    
            </tr>
        </thead>
        <tbody>
            {(rmDatos6MSeleccion) ?
                rmDatos6MSeleccion.map((item) => {
                return (
                    <tr className='table-info' key={item.id_opo_tipo_ingreso}>
                        <td className='text-left'>
                            <div className='input-group'>
                                <div className='input-group-prepend'>
                                    <div className='input-group-text'>
                                        <input
                                            type='radio'
                                            name={`optionRadioCliente6M${item.id_opo_tipo_ingreso}`} id={`optionRadioCliente6M${item.id_opo_tipo_ingreso}`}
                                            value={item.id_cli_rut}
                                            checked={( (item.id_opo_tipo_ingreso === tipoGestionSeleccion) || ((tipoGestionSeleccion === '') && (item.id_opo_tipo_ingreso === 'EEO-000' )) ) ? true : false}
                                            onChange={()=>(tipoGestionSeleccionado(item))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className='text-left'>{item.desc_opo_tipo_ingreso}</td>
                        <td className='text-center'>{item.MES_00}</td>
                        <td className='text-center'>{item.MES_01}</td>
                        <td className='text-center'>{item.MES_02}</td>
                        <td className='text-center'>{item.MES_03}</td>
                        <td className='text-center'>{item.MES_04}</td>
                        <td className='text-center'>{item.MES_05}</td>
                    </tr>
                    );
                })
            : null}
        </tbody>
    </table>
    </>
    )
}

export default ReporteMensualTablaClientes6M;