import React, { Fragment, useContext, useEffect, useState } from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {faHammer, faPaintRoller} from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../spinner/Spinner';
import ReporteMensualTablaClientes from './ReporteMensualTablaClientes';
import ReporteMensualTablaClientes6M from './ReporteMensualTablaClientes6M';
import ReporteMensualTablaClientesEmpresas from './ReporteMensualTablaClientesEmpresas';
import ReporteMensualTablaClientesContactos from './ReporteMensualTablaClientesContactos';
import ServicioContext from '../../../../context/servicio/servicioContext';

const ReporteMensual = () => {
    //const authContext = useContext(AuthContext);
    //const { usuario } = authContext;

    const servicioContext = useContext(ServicioContext);
    const { loadingSpinner, rm6M, rmDatos6M, rmDetalleEmpresa6M, rmDetalleContacto6M, fnReporteMensual6M30D } = servicioContext;

    const [rmDatos6MSeleccion, setRmDatos6MSeleccion] = useState([]);
    const [clienteSeleccion, setClienteSeleccion] = useState('');
    //const [verEmpresas, setVerEmpresas] = useState(true);
    const [tipoGestionSeleccion, setTipoGestionSeleccion] = useState('');
    const [empresaSeleccion, setEmpresaSeleccion] = useState('');
    const [periodoSeleccion, setPeriodoSeleccion] = useState('');
    const [rmDetalleEmpresa6MSeleccion, setRmDetalleEmpresa6MSeleccion] = useState([]);
    const [rmDetalleContacto6MSeleccion, setRmDetalleContacto6MSeleccion] = useState([]);

    const { id_status } = rm6M || {}

    console.log(rmDatos6MSeleccion);
    
    //Llama el State Principal
    useEffect(() => {
        if(id_status) return;
        //console.log(rmClientes);
        
        fnReporteMensual6M30D();
        // eslint-disable-next-line
      }, [id_status]);
    

    //Si hay un cambio en la seleccion del rut de linea/Cliente, refresca la data de los X Meses moviles
    useEffect(() => {
        setRmDatos6MSeleccion(
            rmDatos6M.filter(registro => registro.id_cli_rut === clienteSeleccion)
            .sort(((a,b) => {
                if (a.id_opo_tipo_ingreso < b.id_opo_tipo_ingreso) {
                    return -1;
                }
                if (a.id_opo_tipo_ingreso > b.id_opo_tipo_ingreso) {
                    return 1;
                }
                return 0;
            }))
        );

        setTipoGestionSeleccion([]);
    // eslint-disable-next-line
    }, [clienteSeleccion]);

    //Si hay un cambio en TipoGestion refresca la data rmDetalleEmpresa6M
    useEffect(() => {
        if (tipoGestionSeleccion === 'EEO-000') {
            setTipoGestionSeleccion('');
            setRmDetalleEmpresa6MSeleccion([]);
            setRmDetalleContacto6MSeleccion([]);

        }else{
            setRmDetalleEmpresa6MSeleccion(
                rmDetalleEmpresa6M.filter(registro => registro.id_cli_rut === clienteSeleccion && registro.id_opo_tipo_ingreso === tipoGestionSeleccion )
            );
            setRmDetalleContacto6MSeleccion([]);
        }

    // eslint-disable-next-line
    }, [tipoGestionSeleccion]);


    //Si hay un cambio en ver EmpresaProspecto refresca la data setRmDetalleContacto6MSeleccion
    useEffect(() => {
        setRmDetalleContacto6MSeleccion(
            rmDetalleContacto6M.filter(registro => registro.id_prs_prospecto === empresaSeleccion 
                && registro.id_tie_mes === periodoSeleccion 
                && registro.id_cli_rut === clienteSeleccion
                && registro.id_opo_tipo_ingreso === tipoGestionSeleccion
            )
            
        );            

    // eslint-disable-next-line
    }, [empresaSeleccion]);


    return (
        <Fragment>
            {(loadingSpinner) ?
            <div className='spinner mt-5 pt-5'>
                <div className='col'>
                    <Spinner />
                    <h5 className='text-center mt-5'>Reporte en preparación. Esto puede tardar 90 segundos.</h5>
                </div>
            </div>
            : //null
            <div id= 'navTab' className='container page mb-0'>
                <div className='card border-info mb-3'>
                    <div className='card-header resultados-header'>
                            <h4 className='col-sm-12 col-md-6'>Gestión últimos 6 meses móviles</h4>
                        </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-sm-12 col-lg-6'>
                                {/* Tabla Clientes con gestion (lineas) */}
                                <ReporteMensualTablaClientes
                                    clienteSeleccion = {clienteSeleccion}
                                    setClienteSeleccion = {setClienteSeleccion}
                                />
                            </div>

                            <div className='col-sm-12 col-lg-6'>
                                {/* Tabla Gestion Oportunidades de los X Meses Moviles */}
                                <ReporteMensualTablaClientes6M
                                    
                                    tipoGestionSeleccion = {tipoGestionSeleccion}
                                    rmDatos6MSeleccion = {rmDatos6MSeleccion}
                                    setTipoGestionSeleccion = {setTipoGestionSeleccion}
                                />
                            </div>

                        </div>

                        <div className='row'>
                            {/*
                            <div className='col-sm-12 col-lg-12'>
                                {
                                    //Boton para ver las empresesas de la gestion seleccionada
                                }
                                <button type='button' className='btn btn-secundario m-2' onClick={()=> setVerEmpresas(!verEmpresas)}>
                                    {(!verEmpresas) ? 'Ver' : 'Ocultar'} detalle de Prospección
                                </button>
                            </div>
                            */}
                            

                            {//(verEmpresas) ? 
                                <>
                                    <div className='col-sm-12 col-lg-6'>
                                        {/* Tabla empresesas de la gestion seleccionada */
                                        <ReporteMensualTablaClientesEmpresas
                                            rmDetalleEmpresa6MSeleccion = {rmDetalleEmpresa6MSeleccion}
                                            empresaSeleccion = {empresaSeleccion}
                                            periodoSeleccion = {periodoSeleccion}
                                            setEmpresaSeleccion = {setEmpresaSeleccion}
                                            setPeriodoSeleccion = {setPeriodoSeleccion}
                                        />
                                        }
                                    </div>

                                    <div className='col-sm-12 col-lg-6'>
                                        {/* Tabla Contacto de la empresa/gestion seleccionada */}
                                        <ReporteMensualTablaClientesContactos
                                            rmDetalleContacto6MSeleccion = {rmDetalleContacto6MSeleccion}
                                        />
                                    </div>
                                </>
                            //    : null
                            }
                            

                        </div>
                    </div>
                    
                </div>
            </div>

            }
        </Fragment>
    )
}

export default ReporteMensual;
