import 
{
    SPINNER_ON,
    SPINNER_OFF,
    PREGUNTAS_OBTENER,
    AREAHIJO_SELECCION,
    R01STATE_GUARDAR,
    R02STATE_GUARDAR,
    R03STATE_GUARDAR,
    R04STATE_GUARDAR,
    R05STATE_GUARDAR,
    R06STATE_GUARDAR,
    R07STATE_GUARDAR,
    R08STATE_GUARDAR,
    R09STATE_GUARDAR,
    R10STATE_GUARDAR,
    R11STATE_GUARDAR,
    R12STATE_GUARDAR,
    R13STATE_GUARDAR,
    R14STATE_GUARDAR,
    R15STATE_GUARDAR,
    R16STATE_GUARDAR,
    R17STATE_GUARDAR,
    R18STATE_GUARDAR,
    R19STATE_GUARDAR,
    R20STATE_GUARDAR,
    FICHA_GUARDAROK,
    RESPUESTAS_OBTENER,
    FICHA_ERROR
}
from '../../types';

export default (state, action) => {
    switch(action.type){
        case SPINNER_ON:
            return{
                ...state,
                loading: true
            }
        case SPINNER_OFF:
            return{
                ...state,
                loading: false
            }
        case PREGUNTAS_OBTENER:
            //console.log(action.payload[0]);
            return{
                ...state,
                preguntasFicha: action.payload[0],
                pantallasFicha: action.payload[1],
                areaPadreFicha: action.payload[2],
                areaHijoFicha: action.payload[3],
                sectorFicha: action.payload[4],
                tamanioFicha: action.payload[5],
                plataformaCorreoFicha: action.payload[6],
                basePropiaFicha: action.payload[7],
            }
        case AREAHIJO_SELECCION:
            return{
                ...state,
                areaHijoSeleccion: action.payload
            }
        case R01STATE_GUARDAR:
            return{
                ...state,
                r01Ficha: action.payload
            }
        case R02STATE_GUARDAR:
            return{
                ...state,
                r02Ficha: action.payload
            }
        case R03STATE_GUARDAR:
            return{
                ...state,
                r03Ficha: action.payload
            }
        case R04STATE_GUARDAR:
            return{
                ...state,
                r04Ficha: action.payload
            }
        case R05STATE_GUARDAR:
            return{
                ...state,
                r05Ficha: action.payload
            }
        case R06STATE_GUARDAR:
            return{
                ...state,
                r06Ficha: action.payload
            }
        case R07STATE_GUARDAR:
            return{
                ...state,
                r07Ficha: action.payload
            }
        case R08STATE_GUARDAR:
            return{
                ...state,
                r08Ficha: action.payload
            }
        case R09STATE_GUARDAR:
            return{
                ...state,
                r09Ficha: action.payload
            }
        case R10STATE_GUARDAR:
            return{
                ...state,
                r10Ficha: action.payload
            }
        case R11STATE_GUARDAR:
            return{
                ...state,
                r11Ficha: action.payload
            }
        case R12STATE_GUARDAR:
            return{
                ...state,
                r12Ficha: action.payload
            }
        case R13STATE_GUARDAR:
            return{
                ...state,
                r13Ficha: action.payload
            }
        case R14STATE_GUARDAR:
            return{
                ...state,
                r14Ficha: action.payload
            }
        case R15STATE_GUARDAR:
            return{
                ...state,
                r15Ficha: action.payload
            }
        case R16STATE_GUARDAR:
            return{
                ...state,
                r16Ficha: action.payload
            }
        case R17STATE_GUARDAR:
            return{
                ...state,
                r17Ficha: action.payload
            }
        case R18STATE_GUARDAR:
            return{
                ...state,
                r18Ficha: action.payload
            }
        case R19STATE_GUARDAR:
            return{
                ...state,
                r19Ficha: action.payload
            }
        case R20STATE_GUARDAR:
            return{
                ...state,
                r20Ficha: action.payload
            }
        case FICHA_GUARDAROK:
            return{
                ...state,
                execEnviarFinalizado: action.payload
            }
        case RESPUESTAS_OBTENER:
            return{
                ...state,
                respuestas: action.payload
            }
    
        case FICHA_ERROR:
            return{
                ...state,
                mensaje: action.payload,
                loading: null,
                preguntasFicha: [],
                pantallasFicha: [{id: 'SIN INFORMACION', n: 0}],
                areaPadreFicha: [],
                areaHijoFicha: [],
                areaHijoSeleccion: [],
                sectorFicha: [],
                tamanioFicha: [],
                plataformaCorreoFicha: [],
                basePropiaFicha: [],
                r01Ficha: null,
                r02Ficha: null,
                r03Ficha: null,
                r04Ficha: null,
                r05Ficha: null,
                r06Ficha: null,
                r07Ficha: null,
                r08Ficha: null,
                r09Ficha: null,
                r10Ficha: null,
                r11Ficha: null,
                r12Ficha: null,
                r13Ficha: null,
                r14Ficha: null,
                r15Ficha: null,
                r16Ficha: null,
                r17Ficha: null,
                r18Ficha: null,
                r19Ficha: null,
                r20Ficha: null,
                execEnviarFinalizado: [],
                respuestas: []
            }
        
        default:
            return state;
    }
}