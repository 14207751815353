import React, {useContext, useEffect/*, useState*/} from 'react';
import {useForm, useStep} from 'react-hooks-helper';
import Stepper from './StepForms/Stepper';
import RespuestasFicha from './Respuestas/RespuestasFicha';
import FichaContext from '../../../../context/ficha/fichaContext';


const defaultData = {
    resp0: '',
    resp1: '',
    resp2: '',
    resp3: '',
    resp4: '',
    resp5: '',
    resp6: '',
    resp7: '',
    resp8: '',
    resp9: '',
    resp10: '',
    resp11: '',
    resp12: '',
    resp13: '',
    resp14: '',
    resp15: '',
    resp16: '',
    resp17: '',
    resp18: '',
    resp19: '',
    resp20: '',
    lastName: '',
    nickName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
};


const steps = [
    {id: 'INFORMACIÓN GENERAL', n: 0},
    {id: 'DEFINICIÓN DE TARGET', n: 1},
    {id: 'PROPUESTA DE VALOR', n: 2},
    {id: 'INFORMACIÓN PARA ACTIVACIÓN', n: 3},
    {id: 'DERIVACIÓN DE OPORTUNIDADES', n: 4},
    //{id: 'REVISIÓN', n:5},
    {id: 'ENVIAR', n: 5}
]

const MultiStepForms = () => {
    const fichaContext = useContext(FichaContext);
    const { preguntasFicha, pantallasFicha, respuestas,  fnPreguntasObtener, fnRespuestasObtener } = fichaContext;
    
    //console.log(respuestas);
    //const [steps, setSteps] = useState(pantallasFicha);

    useEffect(() => {
            if(preguntasFicha.length > 0) return;
            //console.log('MUltiStepForms');

            const consultarApi = async () => {
                await fnPreguntasObtener();
                await fnRespuestasObtener();
                //setSteps(pantallasFicha);  
            }

            setTimeout(() => {
                consultarApi();
            }, 50);
            //setCargarData(false);
            //console.log('pasamos por useEffect')

    // eslint-disable-next-line
    }, [preguntasFicha.length]);

    /*
    useEffect(() => {
        if(pantallasFicha.length > 1) return;

        const consultarApi = async () => {
            //await fnPreguntasObtener();
            setSteps(pantallasFicha);  
            console.log(steps);
        }

        setTimeout(() => {
            consultarApi();
        }, 100);
        //setCargarData(false);
        //console.log('pasamos por useEffect')

    // eslint-disable-next-line
    }, [pantallasFicha]);
    */

    const [formData, setForm] = useForm(defaultData);
    const {step, navigation} = useStep({
        steps,
        initialStep: 0
    });

    //console.log(step);
    //const stepsLabel = steps;
    //console.log(stepsLabel);
    const props = {formData, setForm, navigation}

    return (
        <div className='card border-info mb-3'>
            <div className='row'>
                <div className='col-sm-12'>
                    {(respuestas.length > 1) ?
                        <RespuestasFicha />
                    :
                    (preguntasFicha.length > 0) ? 
                        <Stepper 
                            stepsPadre={step}
                            stepsLabel={pantallasFicha}
                            props={props}
                            preguntasFicha={preguntasFicha}
                        />
                        : null
                    }
                    {/*
                        (() => {
                            switch(step.id){
                                case 'propuesta de valor':
                                    return <Names {...props}/>
                                case 'para activación del servicio':
                                    return <Address {...props}/>
                                case 'información general':
                                    return <Contact {...props}/>
                                case 'review':
                                    return <Review {...props}/>
                                case 'submit':
                                    return <Submit {...props}/>
                                default:
                                    // Nada
                            }
                        }).call(this)
                    */}
                </div>
            </div>
                
        </div>
            
            
            
    )
}

export default MultiStepForms;
