import React, {useEffect} from 'react';

const PantallaAreaPadre = ({areaPadreFicha, areaHijoFicha, preguntasFicha, setSeleccion, seleccion, setDisableBtnGuardar, setNAreaPadre, nAreaPadre, setOpcAreaHijo, opcAreaHijo}) => {
    //console.log(areaHijoFicha);
    

    const handleOnChange = (item, checked) => {
        
        let nuevo = [];
        let nContador = [];
        let nuevoHijo = [];

        if(checked){
            //console.log('Añade');
            nuevo = {id: item.id_agrupacion_corta, valor: checked, f_valor: (checked === true) ? 1 : 0}
            setSeleccion([...seleccion, nuevo]);

            nuevoHijo = areaHijoFicha.filter(x => (x.id_agrupacion_corta === item.id_agrupacion_corta));
            //console.log(nuevoHijo);
            setOpcAreaHijo([...opcAreaHijo, nuevoHijo]);
            
            //console.log(nContador);
            nContador = {contador: (nAreaPadre.contador - 1), limite: nAreaPadre.limite}

            setNAreaPadre(nContador);

            /*if (nContador.contador < 0){
                setDisableBtnGuardar(true)
            }*/

            areaHijoFicha.filter(x => (x.id !== item.id_agrupacion_corta && x.valor === true));
            

        }else{
            //console.log('Filtra => Quita');
            nuevo = seleccion.filter(x => (x.id !== item.id_agrupacion_corta && x.valor === true));
            //console.log(seleccion2);
            setSeleccion(nuevo);

            nuevoHijo = opcAreaHijo.filter(x => (x[0].id_agrupacion_corta !== item.id_agrupacion_corta));
            //console.log(nuevoHijo);
            setOpcAreaHijo(nuevoHijo);

            nContador = {contador: (nAreaPadre.contador + 1), limite: nAreaPadre.limite}
            setNAreaPadre(nContador);
        }

    };

    useEffect(() => {
        if(seleccion.length > 3){
            setDisableBtnGuardar(true);
        }else{
            setDisableBtnGuardar(false);
        }

        // eslint-disable-next-line
    }, [seleccion.length])
    
    //console.log(seleccion);
    return (
        <fieldset>
            <div className='form-group'>
                <h5>2.2- {preguntasFicha[2].desc_fip_preguntaT1}</h5>
                <h6 className='ml-2 text-muted'>{preguntasFicha[2].desc_fip_preguntaT2}</h6>
                <h6 className='ml-2 text-muted'>{preguntasFicha[2].desc_fip_preguntaT3}</h6>
                {(nAreaPadre.contador < 0) ?
                    <h6 className='ml-2 text-danger'>{`Excede Selección: ${nAreaPadre.contador}`}</h6>
                : null
                }
                <fieldset>
                    {areaPadreFicha.map((item, index) => {
                        return (
                            <div className='form-check form-switch' key={item.id_agrupacion_corta} >
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id={item.id_agrupacion_corta}
                                    //onClick={() => handleOnChange(item.id_agrupacion_corta)}
                                    //checked={checkedState[index]}
                                    onChange={(e) => handleOnChange(item, e.target.checked)}
                                    //defaultValue={defaultValue}
                                    //disabled={false}
                                />
                                <label className='form-check-label' htmlFor={item.id_agrupacion_corta}>{item.desc_agrupacion_corta}</label>
                            </div>
                        )
                    })}
                </fieldset>
                {/*<textarea name='resp1' className='form-control' rows='1' defaultValue={resp1} onChange={setForm}></textarea>*/}
            </div>
        </fieldset>

    )
}

export default PantallaAreaPadre;
