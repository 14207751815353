import React, { Fragment, useContext, useEffect } from 'react';
import Spinner from '../../spinner/Spinner';

import { ServicioNavTabs, ServicioTabContent} from './ServicioData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ServicioContext from '../../../../context/servicio/servicioContext';
import AlertaContext from '../../../../context/alerta/alertaContext';

const Servicio = () => {
    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;

    const servicioContext = useContext(ServicioContext);
    const { mensaje, loadingSpinner } = servicioContext;

    useEffect(() => {
        if(mensaje) {
            mostrarAlerta(mensaje.msg, mensaje.categoria);
        }
        //eslint-disable-next-line
    }, [mensaje]);

    return (
        <Fragment>
            {(loadingSpinner) ? <div className='spinner mt-5 pt-5'><Spinner/></div>
            : //null
            <div id= 'navTab' className='container page'>
                <ul className='nav nav-tabs nav-justified flex-columns'>
                    { alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null }
                    {ServicioNavTabs.map((item, index) => {
                        return (
                            <li key={index} className={item.cNameLi}>
                                <a href={item.path} className={item.cNameA} data-toggle={item.dataToggle}>
                                    <FontAwesomeIcon icon={item.icon} /> <span className=''>{item.title}</span>
                                </a>
                            </li>
                        );
                    })}
                </ul>
                <div id='contentTab' className='tab-content'>
                    {ServicioTabContent.map((item, index) => {
                    return(
                        <div key={index} className={item.cName} id={item.id}>
                            {item.component}
                        </div>
                    )
                    })}
                </div>
            </div> 
            }


      </Fragment>
    )
}

export default Servicio;
